.c-image-block {

    &__background {
        height: auto;
        // width: 715px;
        @include border-radius(10px);

        @include media-breakpoint-up(lg) {
            width: 960px;
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__title {
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        color: #0a5f55;
    }

    &--left-overflow,
    &--right-overflow{
        overflow: visible;
    }

    &--left-overflow {
        &__background {
            float: right;
        }
    }

    &--right-overflow {
        &__background {
            float: left;
        }

        &\@tablet-and-up{
            @include media-breakpoint-up(md) {
                &__background {
                    float: left;
                }
            }
        }
    }

    &--full-width {
        &\@mobile {
            margin-left: -10px;
            margin-right: -10px;
            @include media-breakpoint-down(sm) {
                .c-image-block__background {
                    float: none;
                    width: 100%;
                }
            }
        }
    }

}

/* GX */

.c-image-block__background img {
    @extend .c-image-block__image;
    @include border-radius(10px);
}

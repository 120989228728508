footer, .footer {
    // background-color: $color-green;
    // color: $color-white;

    @extend %gtwalsheim-normal;
    font-size: 0.8125em;

    a{
        color:#fff;
        text-decoration: none;
    }


    .footer-top-left {
        display: inline-block;
        line-height: 30px;
        border-right: 1px solid #fff;
        padding: 0 20px 0 0;

        a{
            display: inline-block;
            width: 30px;
            height: 30px;
            margin-right: 15px;
            overflow: hidden;
            color: transparent;

            &:last-child{
                margin-right:0;
            }
        }
        a.social-facebook{
            &:before{
                // @include bovemij-icon('social-facebook');
                color: #fff;
                font-size:28px;
            }
        }

        a.social-twitter{
            &:before{
                // @include bovemij-icon('social-twitter');
                color: #fff;
                font-size:28px;
            }
        }

        a.social-linkedin{
            &:before{
                // @include bovemij-icon('social-linkedin');
                color: #fff;
                font-size:28px;
            }
        }
    }

    .footer-bottom {
        display: inline-block;
        line-height: 30px;

        a{
            padding: 0 20px;
        }

    }

    .footer-top-right{
        display: inline-block;
        float: right;

        p{
            display: inline-block;
            line-height: 30px;
            vertical-align: middle;
            padding-right:20px;
        }

        div{
            display: inline-block;
            vertical-align: middle;


            img{
                    height: 30px;
                    width:auto;
                }

        }
    }

}

.u-mb-0 {
    margin-bottom: 0 !important;

    &\@mobile {
        @include media-breakpoint-down(sm) {
             margin-bottom: 0 !important;
        }
    }

    &\@tablet {
        @include media-breakpoint-only(md) {
            margin-bottom: 0 !important;
        }
    }

    &\@tablet-and-up {
        @include media-breakpoint-up(md) {
             margin-bottom: 0 !important;
        }
    }

    &\@desktop {
        @include media-breakpoint-up(lg) {
             margin-bottom: 0 !important;
        }
    }
}

.u-mb-25 {
    margin-bottom: 25px !important;

    &\@mobile {
        @include media-breakpoint-down(sm) {
             margin-bottom: 25px !important;
        }
    }

    &\@tablet-and-down {
        @include media-breakpoint-down(md) {
            margin-bottom: 25px !important;
        }
    }

    &\@tablet {
        @include media-breakpoint-only(md) {
            margin-bottom: 25px !important;
        }
    }

    &\@tablet-and-up {
        @include media-breakpoint-up(md) {
             margin-bottom: 25px !important;
        }
    }

    &\@desktop {
        @include media-breakpoint-up(lg) {
             margin-bottom: 25px !important;
        }
    }
}

.u-mb-50 {
    margin-bottom: 50px !important;

    &\@mobile {
        @include media-breakpoint-down(sm) {
            margin-bottom: 50px !important;
        }
    }

    &\@tablet-and-down {
        @include media-breakpoint-down(md) {
            margin-bottom: 50px !important;
        }
    }

    &\@tablet {
        @include media-breakpoint-only(md) {
            margin-bottom: 50px !important;
        }
    }

    &\@tablet-and-up {
        @include media-breakpoint-up(md) {
            margin-bottom: 50px !important;
        }
    }

    &\@desktop {
        @include media-breakpoint-up(lg) {
            margin-bottom: 50px !important;
        }
    }
}

.u-mb-75 {
    margin-bottom: 75px !important;

    &\@mobile {
        @include media-breakpoint-down(sm) {
            margin-bottom: 75px !important;
        }
    }

    &\@tablet-and-down {
        @include media-breakpoint-down(md) {
            margin-bottom: 75px !important;
        }
    }

    &\@tablet {
        @include media-breakpoint-only(md) {
            margin-bottom: 75px !important;
        }
    }

    &\@desktop {
        @include media-breakpoint-up(lg) {
            margin-bottom: 75px !important;
        }
    }
}

.u-mb-100 {
    margin-bottom: 100px !important;

    &\@mobile {
        @include media-breakpoint-down(sm) {
            margin-bottom: 100px !important;
        }
    }

    &\@tablet-and-down {
        @include media-breakpoint-down(md) {
            margin-bottom: 100px !important;
        }
    }

    &\@tablet {
        @include media-breakpoint-only(md) {
            margin-bottom: 100px !important;
        }
    }

    &\@tablet-and-up {
        @include media-breakpoint-up(md) {
            margin-bottom: 100px !important;
        }
    }

    &\@desktop {
        @include media-breakpoint-up(lg) {
            margin-bottom: 100px !important;
        }
    }
}

.u-move-up-40 {
    margin-top: -40px;

    &\@tablet-and-up {
        @include media-breakpoint-up(md) {
            margin-top: -40px;
        }
    }

    &\@desktop {
        @include media-breakpoint-up(lg) {
            margin-top: -40px !important;
        }
    }
}

.h2-no-margin {
    h2 {
        margin: 0;
    }
}
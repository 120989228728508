$border-color: #dadada;
$link-color: #191919;
$link-color-with-icon: #123c81;
$title-color: rgba(48, 171, 122, 0.2);

.c-links-block {
    position: relative;

    &__title {
        margin-bottom: 8px;
        font-family: "GT Walsheim Pro";
        font-size: 24px;
        font-weight: 500;
        line-height: 1;
        color: $title-color;
        text-align: left;

        @include media-breakpoint-up(lg) {
            font-size: 40px;

            &__large {
                font-size: 60px;
                margin-bottom: -5px;
            }
        }
    }

    &__item {
        border-bottom: 2px solid $border-color;

        &:last-child {
            border-bottom: 0 none;
        }

        a {
            text-decoration: none;
        }
    }

    &__link {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 80px;
        padding: 0 15px;
        font-family: "GT Walsheim Pro";
        text-align: left;
        color: $link-color;

        &:hover {
            text-decoration: underline;
        }

        &--icon {
            padding: 0 0 0 72px;
            &:before {
                position: absolute;
                top: 50%;
                left: 20px;
                height: 32px;
                width: 32px;
                font-size: 32px;
                font-weight: 300;
                color: $link-color-with-icon;
                @include transform(translateY(-50%));
            }
        }
    }

    &--rectangle {
        display: inline-block;

        .c-links-block {
            &__list {
                @include inline-flex;
                @include flex-direction(row);
                @include flex-wrap(nowrap);
                @include justify-content(flex-start);
                @include align-content(stretch);
                @include align-items(flex-start);
            }

            &__item {
                @include order(0);
                @include flex(0, 1, auto);
                @include align-self(auto);
                width: 150px;
                height: 126px;
                position: relative;
                text-align: center;
                border-bottom: 0 none;
                border-right: 1px solid $border-color;

                &:last-child {
                    border-right: 0 none;
                }

                &--large {
                    width: 182px;
                    height: 150px;
                }

                &--responsive {
                    width: 50%;
                    height: 150px;
                }
            }

            &__link {
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
                font-family: "GT Walsheim Pro";
                font-weight: 300;
                text-align: left;
                color: $link-color;
                text-decoration: none;
                //word-break: break-all;
                text-align: center;

                &--icon {
                    padding: 73px 32px 32px;

                    &:before {
                        position: absolute;
                        top: 32px;
                        left: 50%;
                        padding: 0;
                        height: 32px;
                        width: 32px;
                        font-size: 32px;
                        @include transform(translateX(-50%));
                    }
                }
            }
        }

        &\@desktop {
            @include media-breakpoint-up(lg) {
                display: inline-block;

                .c-links-block {
                    &__list {
                        @include inline-flex;
                        @include flex-direction(row);
                        @include flex-wrap(nowrap);
                        @include justify-content(flex-start);
                        @include align-content(stretch);
                        @include align-items(flex-start);
                    }

                    &__item {
                        @include order(0);
                        @include flex(0, 1, auto);
                        @include align-self(auto);
                        width: 150px;
                        height: 126px;
                        position: relative;
                        text-align: center;
                        border-bottom: 0 none;
                        border-right: 1px solid $border-color;

                        &:last-child {
                            border-right: 0 none;
                        }

                        &--large {
                            width: 182px;
                            height: 150px;
                        }

                        &--responsive {
                            width: 50%;
                            height: 150px;
                        }
                    }

                    &__link {
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 100%;
                        font-family: "GT Walsheim Pro";
                        font-weight: 300;
                        text-align: left;
                        color: $link-color;
                        text-decoration: none;
                        //word-break: break-all;
                        text-align: center;

                        &--icon {
                            padding: 73px 32px 32px;

                            &:before {
                                position: absolute;
                                top: 0;
                                left: 50%;
                                padding: 0;
                                height: 32px;
                                width: 32px;
                                font-size: 32px;
                                @include transform(translateX(-50%));
                            }
                        }
                    }
                }
            }
        }
    }

    &--square\@desktop {
        @include media-breakpoint-up(lg) {
            .c-links-block {
                &__list {
                    @include flexbox;
                    @include flex-wrap(wrap);
                    @include flex-direction(row);
                }

                &__item {
                    display: flex;
                    flex-basis: 50%;
                    -ms-flex-preferred-size: calc(50% - 1px);
                    justify-content: center;
                    flex-direction: column;
                    border-bottom: 0 none;

                    &:first-child,
                    &:nth-child(2) {
                        border-bottom: 2px solid $border-color;
                    }

                    &:nth-child(odd) {
                        border-right: 1px solid $border-color;
                    }

                    &:nth-child(even) {
                        border-left: 1px solid $border-color;
                    }
                }

                &__link {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                    width: 100%;
                    height: auto;
                    padding: 74px 5px 30px;

                    &--icon {
                        &:before {
                            top: 30px;
                            left: 50%;
                            -webkit-transform: translateX(-50%);
                            -moz-transform: translateX(-50%);
                            -ms-transform: translateX(-50%);
                            -o-transform: translateX(-50%);
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }

    &--2x2-grid\@desktop {
        @include media-breakpoint-up(lg) {
            &__list {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
            }

            &__item {
                display: flex;
                flex-basis: 50%;
                justify-content: center;
                flex-direction: column;
                border-bottom: 0 none;

                &:first-child,
                &:nth-child(2) {
                    border-bottom: 2px solid $border-color;
                }

                &:nth-child(odd) {
                    border-right: 1px solid $border-color;
                }

                &:nth-child(even) {
                    border-left: 1px solid $border-color;
                }
            }

            &__link {
                display: flex;
                justify-content: center;
                flex-direction: row;
                width: 100%;
                height: auto;
                padding: 74px 5px 30px;

                &--icon {
                    &:before {
                        position: absolute;
                        top: 30px;
                        left: 50%;
                        font-size: 32px;
                        -webkit-transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        -o-transform: translateX(-50%);
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}

/* GX */

.c-links-block__item a {
    @extend .c-links-block__link;
}

.c-links-block__item a[class^="icon-"]{
    @extend .c-links-block__link;
    @extend .c-links-block__link--icon;
}

.c-links-block--2x2-grid\@desktop__item a {
    @extend .c-links-block--2x2-grid\@desktop__link;
    @extend .c-links-block--2x2-grid\@desktop__link--icon;
}


.c-links-block--square\@desktop .c-links-block__item a[class^="icon-"] {
    color: $link-color-with-icon;

    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        height: auto;
        padding: 74px 5px 30px;
        text-decoration: none;
        text-align: center;

        &:before {
            position: absolute;
            top: 30px;
            left: 50%;
            font-size: 32px;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
        }
    }
}

.c-links-block--rectangle\@desktop .c-links-block__item > div{
    width: 100%;
    height: 100%;
}

.c-links-block--rectangle\@desktop .c-links-block__item a[class^="icon-"] {
    color: $link-color-with-icon;

    @include media-breakpoint-up(lg) {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        font-family: "GT Walsheim Pro";
        font-weight: 300;
        text-align: left;
        text-decoration: none;
        //word-break: break-all;
        text-align: center;

        &:before {
            position: absolute;
            top: 32px;
            left: 50%;
            height: 32px;
            width: 32px;
            padding: 0;
            font-size: 32px;
            @include transform(translateX(-50%));
        }
    }
}

.c-links-block--rectangle\@desktop .c-links-block__item a[class^="icon-"] {
    @include media-breakpoint-up(lg) {
        padding: 74px 10px 30px 10px;
    }
}

.c-search {
    .searchresult-default {

        .search-result-index {
            margin-bottom: 40px;
        }

        .search-wrapper {
            ol {
                list-style: none;
                padding-left: 0;

                li {
                    border-bottom: 1px solid #d2d2d5;
                    margin-bottom: 24px;
                    padding-bottom: 24px;

                    &:last-child {
                        border-bottom: 0;
                        padding-bottom: 0;
                    }

                    > a {
                        text-decoration: none;
                        color: $text-black;
                    }

                    .fieldvalue-title {
                        color: #123c81;
                        font-size: 16px;
                        font-weight: bold;

                        @include media-breakpoint-up(md) {
                            font-size: 22px;
                        }
                    }

                    .meta .category,
                    .fieldvalue-contenttype {
                        font-size: 14px;
                        color: #0a5f55;
                    }

                    .fieldvalue-location {
                        display: none;
                    }

                    .summary {
                        margin-top: 20px;
                    }

                    h3 {
                        margin-top: 20px;
                        font-size: 0;
                        text-decoration: none;

                        &:before {
                            color: $color-green;
                            text-decoration: none;
                            padding-right: 10px;
                            content: "\f178";
                            display: inline-block;
                            font: normal normal normal 14px/1 FontAwesome;
                            font-size: 14px;
                            font-size: inherit;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            font-size: 16px;
                        }

                        &:after {
                            content: "Lees meer";
                            font-size: 16px;
                            color: $text-black;
                            font-weight: normal;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

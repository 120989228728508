.c-tips {
    &__item {
        position: relative;
        padding: 0 10px;
    }

    &__image {
        width: 100%;
        height: auto;
        min-height: 40px;
        @include border-radius(10px);
    }

    &__block {
        margin: -40px 10px 0;
        padding: 20px;
    }

    &__title {
        margin: 0 0 10px 0;
    }

    &__meta {
        padding-top: 20px;
        border-top: 1px solid #ececec;
        @include clearfix;
    }

    &__name {
        position: relative;
        float: left;
        padding: 16px 0 0 0;
        color: #0a519d;
        font-size: 14px;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "Door";
            font-size: 12px;
            color: #191919;
        }
    }

    &__date {
        float: right;
        font-size: 14px;
        width: auto;
        color: #191919;
        padding: 16px 0 0 0;

        &:before {
            position: absolute;
            right: 0;
            top: 0;
            content: "Datum";
            font-size: 12px;
            color: #191919;
        }

        &--icon {
            position: relative;
            padding-left: 22px;

            &:after {
                position: absolute;
                top: 18px;
                left: 0;
                @include bovemij-icon(calendar);
                color: #0a519d;
                font-size: 15px;
                margin: 0 5px 0 0;
            }
        }
    }

    &__thumbnail {
        float: left;
        position: relative;
        width: 38px;
        height: 38px;
        margin: 0 10px 0 0;
        overflow: hidden;
        background: linear-gradient(52deg, #29ac79, #113d83);
        @include border-radius(50%);

        &:after{
            content: "";
            position: absolute;
            top: 1px;
            left: 1px;
            width: 36px;
            height: 36px;
            background-color: #FFF;
            @include border-radius(50%);
            z-index: 1;
        }
    }

    &__thumbnail-image {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 36px;
        height: 36px;
        background-color: #FFF;
        z-index: 2;
    }
}

/* XC */

.c-tips__image img {
    display: block;
    width: 100%;
    height: auto;
    @include border-radius(10px);
}

.c-tips__thumbnail img {
    @extend .c-tips__thumbnail-image;
}
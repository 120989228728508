.c-company-selection { 
    @include media-breakpoint-up(md) {
        position: relative;
        display: block;
        height: auto;
        padding: 40px 30px 50px 30px;
        background-color: #FFF;
        @include border-bottom-radius(10px);
    }

    &:after {
        content: none;

        @include media-breakpoint-up(md) {
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: -webkit-linear-gradient(70deg, #29ac79, #113d83);
            background-image: -moz-linear-gradient(70deg, #29ac79, #113d83);
            background-image: -ms-linear-gradient(70deg, #29ac79, #113d83);
            background-image: -o-linear-gradient(70deg, #29ac79, #113d83);
            background-image: linear-gradient(70deg, #29ac79, #113d83);
            z-index: -1;
            @include border-bottom-radius(10px);
        }
    }

    &.is-collapsed {
        @include media-breakpoint-up(lg) {
            height: 70px;
            padding: 25px 0 25px 30px;
        }
    }

    &__select-type {
        &.is-hidden {
            display: none;
            visibility: hidden;
        }
    }

    &__selected-type {
        &.is-hidden {
            display: none;
            visibility: hidden;
        }
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 32px;
        height: 32px;
        overflow: hidden;
    }

    &__close-button {
        display: block;
        width: 32px;
        height: 32px;
        font-size: 28px;
        color: transparent;

        &:before {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 24px;
            color: #0a519d;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }

    &__open {
        position: absolute;
        top: 0;
        right: 0;
        width: 70px;
        height: 70px;
        overflow: hidden;
        border-left: 1px solid #d8d8d8;
    }

    &__open-button {
        display: block;
        width: 70px;
        height: 70px;
        font-size: 24px;
        color: transparent;

        &:before {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 24px;
            color: #0a519d;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }
}

/* GX */

.c-company-selection__close a {
    @extend .c-company-selection__close-button;
}

.c-company-selection__open a {
    @extend .c-company-selection__open-button;
}
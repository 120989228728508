.c-search-results-filters {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 24px;
    z-index: 1200;
    padding: 19px 16px 30px;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, .5);
    background: #fff;

    @include media-breakpoint-up(sm) {
        position: static;
        padding: 0;
        box-shadow: none;
        background: transparent;
    }

    &__title {
        margin-bottom: 30px;
        font-size: 16px;

        @include media-breakpoint-up(sm) {
            font-size: 20px;
        }
    }

    &__filters {

    }

    &__filter {
        & + & {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid $color-border;

            @include media-breakpoint-up(sm) {
                margin-top: 24px;
                padding-top: 0;
                border-top: 0;
            }
        }

        .custom-checkbox {
            margin-bottom: 0;
            padding-left: 42px;
        }

        .custom-control-input {
            &:checked + .custom-control-indicator {
                @extend %bovemij-icon;
                @extend .icon-check;
                color: $color-green;
                background-image: none;
                background-color: #f1f1f1;

                &::before {
                    position: relative;
                    top: -3px;
                    left: 4px;
                    font-size: 15px;
                }
            }

            &:focus + .custom-control-indicator {
                box-shadow: 0 0 0 1px #fff, 0 0 0 3px $color-green;
            }
        }


        .custom-control-indicator {
            top: 0;
            width: 24px;
            height: 24px;
            background-color: #f1f1f1;
            border: solid 1px #b1b1b1;
            border-radius: 0;
        }
    }

    &__mobile-close {
        @extend %bovemij-icon;
        @extend .icon-close;
        margin-bottom: 30px;
        font-size: 19px;
        color: $text-blue;
        text-align: right;

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &__mobile-submit {
        margin-top: 40px;

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }
}

// Show the button on edit mode.
.claro .c-search-results-filters__mobile-submit {
    display: inline-block !important;
}



.c-contact-us {
    position: relative;
    align-items: flex-end;
    display: flex;
    height: 100%;

    &__speech-bubble {
        margin: 0 0 128px 0;
    }

    &__person {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 150px;
        height: 200px;
        overflow: hidden;
        z-index: 9;
    }

    &__person-name {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &__bottom {
        width: 950px;
        position: absolute;
        height: 77px;
        display: block;
        z-index: 20;
        background-image: url('../../img/contact-us-bottom.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__meta {
        position: absolute;
        bottom: 48px;
        right: 150px;
        width: 150px;

        @include media-breakpoint-only(md) {
            width: 131px;
        }

        &:before {
            position: absolute;
            bottom: -24px;
            right: 5px;
            font-size: 28px;
            -webkit-transform: rotate(325deg);
            -moz-transform: rotate(325deg);
            -ms-transform: rotate(325deg);
            -o-transform: rotate(325deg);
            transform: rotate(325deg);
            @include bovemij-icon(arrow-handdrawn);
            color: #0a519d;
        }
    }

    &__name {
        font-size: 14px;
        color: #0a519d;
    }

    &__profession {
        font-size: 14px;
        color: #191919;
    }

    &__button{
        z-index: 99;
    }
}

body {
    @extend %gtwalsheim-normal;
    color: #191919;
    font-size: 16px;
    line-height: 1.5;
}

h1 {
    color: $text-blue;
    font-size: 32px;
}

h2 {
    @extend %gtwalsheim-medium;
    margin-bottom: 20px;
    color: $text-blue;
    font-size: 22px;
    line-height: 1.5;

    > a {
        position: static !important;
        color: $text-blue;
        text-decoration: none;

        &:before {
            margin-right: 15px;
        }
    
        &:hover, &:focus {
            text-decoration: none;
            outline: 0 none;
            cursor: text;
        }
    }
}

h3 {
    @extend %gtwalsheim-medium;
    font-size: 16px;
    color: $text-blue;
    line-height: 1.5;
    margin-bottom: 20px;
}

p{
    @extend %gtwalsheim-normal;
    margin-bottom: 30px;
    color: $text-black;
    line-height: 1.5;
}

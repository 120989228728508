.o-text-block {
    &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
        color: #123c81;
        word-wrap: break-word;
        position: relative;

        @include media-breakpoint-up(md) {
            font-size: 22px;
        }

        &--underline {
            position: relative;
            margin: 0 0 30px 0;
            overflow: hidden;
            text-align: center;

            &:before, &:after {
                content: "";
                position: absolute;
                top: 30px;
                height: 20px;

                @include media-breakpoint-up(lg) {
                    top: 24px;
                }
            }

            &:before {
                width: 70%;
                left: 0;
                border-bottom: 2px solid #30ab7a;
                z-index: 1;
            }

            &:after {
                right: 0;
                width: 219px;
                background-image: url(../../img/bg_header_block.png);
                background-repeat: no-repeat;
                z-index: 2;
            }
        }
    }

    &__text {
        line-height: 1.5;
        color: #1a1a1a;
    }
}

/* XC */
.o-text-block .o-text-block__title--underline a {
    display: block;
    color: inherit;
    text-decoration: none;
    min-height: 32px;

    &:before {
        display: inline-block;
        width: 32px;
        height: 32px;
        font-size: 32px;
        margin-right: 10px;
    }
}

.paging-default {
    .wrapper {
        text-align: center;

        @include media-breakpoint-up(sm) {
            text-align: left;
        }  
    }

    span {
        &.prev, &.next {
            display: none;
        }

        &.first, &.last, &.more {
            display: none;

            @include media-breakpoint-up(sm) {
                display: inline-block;
            }  
        }

        &.more {
            margin-right: 10px;
        }

        &.activeLink {
            display: inline-block;
            padding: 8px 16px;
            border-radius: 10px;
            border: solid 1px #128964;
            background-color: #30ab7a;
            margin-right: 10px;
            color: #FFF;
        }
    }

    a {
        margin-right: 10px;
        display: inline-block;
        padding: 8px 16px;
        background-color: $color-white;
        border-radius: 10px;
        border: solid 1px #d2d2d5;
        text-decoration: none;

        &:last{
            margin: 0;
        }
    }
}
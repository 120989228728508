.c-button-block {
    padding: 30px 15px 45px 15px;
    margin: 0 0 21px 0;

    @include media-breakpoint-up(md) {
        padding: 30px 30px 45px 30px;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__body {
        word-break: normal;
    }

    &__list-item {
        position: relative;
        padding: 0 0 0 26px;
        color: #191919;
        margin-bottom: 15px;

        &:before {
            position: absolute;
            top: 3px;
            left: 0;
        }
    }

    &__button {
        min-width: 210px;
        max-width: 290px;
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 42px;
        -moz-transform: translate(-50%, 50%);
        -webkit-transform: translate(-50%, 50%);
        -o-transform: translate(-50%, 50%);
        -ms-transform: translate(-50%, 50%);
        transform: translate(-50%, 50%);

        @include media-breakpoint-up(md) {
            max-width: 320px;
        }
    }
}

/* GX */

.c-button-block__body ul li {
    @extend .c-button-block__list-item;
}

.c-button-block__button .btn {
    @extend .btn-block; 
    min-width: 210px;
    max-width: 290px;
    @include media-breakpoint-up(md) {
        max-width: 320px;
    }
}
.o-list {
    margin: 0;
    padding: 0;
    list-style: none !important;

    li {
        
        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
        }
    }

    &--small {
        li {
            font-size: 14px;
        }
    }

    &--checkmarks &__item,
    &--checkmarks ul li {
        position: relative;
        padding-left: 25px;

        &:before {
            @include bovemij-icon(check);
            position: absolute;
            left: 0;
            top: 3px;
        }
    }

    &--crosses &__item,
    &--crosses ul li {
        position: relative;
        padding-left: 25px;

        &:before {
            @include bovemij-icon(cross);
            position: absolute;
            left: 0;
            top: 3px;
            color: $primair-default;
        }
    }

    &--arrows &__item,
    &--arrows ul li {
        &:before {
            content: "\f178";
            display: inline-block;
            margin-right: 10px;
            color: #30ab7a;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    &--dotted &__item,
    &--dotted ul li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 10px;

        &:before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #30ab7a;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 5px;
        }
    }

    &--count &__item,
    &--count ul {
        counter-reset: listcount;

        li {
            counter-increment: listcount;
            position: relative;
            padding-left: 50px;
            min-height: 37px;
            margin-bottom: 10px;

            &:before {
                content: counter(listcount);
                position: absolute;
                left: 0;
                top: 5px;
                width: 32px;
                height: 32px;
                border-radius: 10px;
                background-color: #30ab7a;
                color: #fff;
                text-align: center;
                line-height: 32px;
                border: 1px solid #128964;
                font-size: 16px;
            }
        }
    }

    &--icon &__item,
    &--icon ul li {
        min-height: 40px;
        margin-bottom: 10px;

        a {
            display: block;
            position: relative;
            padding-left: 50px;
            text-decoration: none;
            color: #191919;

            &:before {
                position: absolute;
                left: 0;
                top: 5px;
                font-size: 30px;
                color: #123C81;
            }
        }
    }

    &--date &__item,
    &--date {

        &__item {
            position: relative;
            margin-bottom: 20px;

            a {
                text-decoration: none;
                color: $black;
            }

            &--date {
                position: absolute;
                color: $text-blue-light;
            }

            &--title {
                padding-left: 50px;
            }
        }
    }

    &--black-links a {
        color: #191919;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

/* GX */

.o-list ul {
    @extend .o-list;
}

.c-quote-block {
    position: relative;

    &__quote {
        /* Begin temp positioning */
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        /* End temp positioning */
        left: 0;
        height: 200px;
        width: 75%;
        max-width: 237px;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
        overflow: auto;
        z-index: 3;

        @include media-breakpoint-up(md) {
            width: 27.8481%;
            max-width: none;
        }
    }

    &__background {
        display: block;
        width: auto;
        height: 370px;
        margin-bottom: 15px;
        z-index: 1;
        @include border-radius(10px);

        @include media-breakpoint-up(md) {
            width: 100%;
            height: auto;
            margin-bottom: 0;
        }
    }

    &__meta {
        padding-left: 25%;
        color: #191919;

        @include media-breakpoint-up(md) {
            position: absolute;
            bottom: 0;
            left: 0;
            height: auto;
            width: 790px;
            padding: 24px;
            color: #FFF;
            background-color: rgba(26, 26, 26, 0.35);
            z-index: 2;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    &__person {
        display: block;
        font-size: 16px;
        line-height: 1.5;
    }

    &__department {
        display: block;
        font-size: 12px;
        line-height: 1.5;
    }

    &--right-overflow {
        overflow: visible;
        float: left;

        @include media-breakpoint-up(md) {
            width: 790px;
        }

        &__background {
            padding-left: 10.12658%;

            @include media-breakpoint-up(md) {
                width: 710px;
            }

            @include media-breakpoint-up(lg) {
                width: 840px;
            }
        }

        &__meta {
            padding-left: 10.12658%;

            @include media-breakpoint-up(md) {
                padding-left: 24px;
                width: 710px;
                left: 10.12658%;
            }

            @include media-breakpoint-up(lg) {
                width: 840px;
            }
        }
    }

    &--left-overflow {
        overflow: visible;
        float: right;

        @include media-breakpoint-up(md) {
            width: 790px;
        }

        &__background {
            padding-right: 10.12658%;

            @include media-breakpoint-up(md) {
                width: 710px;
            }

            @include media-breakpoint-up(lg) {
                width: 840px;
            }
        }

        &__meta {
            text-align: right;
            padding-right: 10.12658%;

            @include media-breakpoint-up(md) {
                width: 710px;
                padding-right: 20px;
            }

            @include media-breakpoint-up(lg) {
                width: 840px;
                left: -21.12658%;
            }
        }

        .c-quote-block__quote {
            left: auto;
            right: 0;
        }
    }
}

/* GX */
.c-quote-block__background img {
    @extend .c-quote-block__background;
}

.c-quote-block--right-overflow__background img
{
    @extend .c-quote-block--right-overflow__background;
    margin: 0;
    padding: 0;
}

.c-quote-block--left-overflow__background img
{
    @extend .c-quote-block--left-overflow__background;
    margin: 0;
    padding: 0;
}

.c-quote-block--left-overflow__background .thumb {
    @include media-breakpoint-up(lg) {
        float: right;
        margin-right: 87px;
    }
}

.c-content-block {
  &__item {
    margin-bottom: 10px;
    width: 300px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: inherit;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__title {
    margin: 0;
  }

  &__content-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
  }

  &__read-more {
    text-decoration: none;

    &:before {
      content: '\2192';
      font-size: 40px;
      font-weight: 100;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__date {
    float: left;
    font-size: 14px;
    width: auto;
    color: #191919;
    padding: 0 0 16px 0;

    &:before {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      color: #191919;
    }

    &--icon {
      position: relative;
      padding-left: 22px;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        @include bovemij-icon(calendar);
        color: #0a519d;
        font-size: 15px;
        margin: 0 5px 0 0;
      }
    }
  }

  &__image {
    width: inherit;
    height: auto;
    min-height: 40px;
    @include border-radius(10px);

    img {
      display: block;
      height: auto;
      width: 100%;
      margin: 0 auto;

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.c-content-blocks-wrapper {
  align-items: top;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

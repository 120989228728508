$quote-color: #123c81;

.c-quote {
    overflow: hidden;

    &__text {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        font-family: "GT Walsheim Pro";
        color: $quote-color;
        line-height: 1.5;
    }

    &--with-link {
        &__link{
            position: relative;
            display: block;
            width: 100%;
            height: 100%;

            &:before,
            &:after {
                position: absolute;
            }

            &:before {
                top: 50%;
                right: 8px;
                width: 24px;
                height: 24px;
                color: #FFF !important;
                font-size: 24px;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                @include bovemij-icon(arrow-next);
                z-index: 2;
            }

            &:after {
                content: "";
                top: 0;
                right: 0;
                width: 40px;
                height: 100%;
                background-color: #30ab7a;
                z-index: 1;
            }
        }
    }
}

/* GX */

.c-quote {
    @extend .c-quote__text;
    padding: 30px 15px;
}

.c-quote a {
    @extend .c-quote--with-link__link;   
}
.c-tertiary-nav {
    &\@mobile {
        position: relative;
        display: block;
        visibility: visible;
        padding-top: 30px;

        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
            border-style: solid;
            border-width: 1px;
            border-image-source: linear-gradient(to right, rgba(48, 171, 122, 0), #1f9465 50%, rgba(48, 171, 122, 0));
            border-image-slice: 1;
        }

        @include media-breakpoint-only(sm) {
            width: 300px;
            margin: 0 auto;
        } 

        @include media-breakpoint-up(lg) {
            display: none;
            visibility: hidden;
        }  
    }

    &\@desktop {
        display: none;
        visibility: hidden;

        @include media-breakpoint-up(lg) {
            display: block;
            visibility: visible;
        }  
    }
}

.c-tertiary-nav__list {
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;

    @include media-breakpoint-up(md) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }   
}

.c-tertiary-nav__item {
    color: #FFF;
    
    @include media-breakpoint-up(md) {
        display: inline-block;
        line-height: 60px;
    }

    > i.fa {
        display: inline-block;
    }
}

.c-tertiary-nav__item-link {
    display: inline-block;
    color: #FFF;
    text-decoration: none;
    font-size: 14px;

    &:hover {
        color: #FFF;
        text-decoration: underline;
    }

    @include media-breakpoint-up(md) {
        margin-right: 30px;
    }
}

/* XC */
.c-tertiary-nav {
    &\@mobile,
    &\@desktop{
        ul {
            @extend .c-tertiary-nav__list;
            li {
                @extend .c-tertiary-nav__item;
                a {
                    @extend .c-tertiary-nav__item-link;
                    &:before {
                        content: "\f178";
                        display: inline-block;
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: inherit;
                        margin-right: 10px;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale; 
                    }
                }
            }
        }
    }
}
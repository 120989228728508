.c-phone-block {

    &__item {
        padding: 24px 16px;

        &__title {
            margin-bottom: 25px;
            color: $text-blue;

            @include media-breakpoint-up(md) {
                text-align: center;
            }
        }

        &__phoneNumber {
            position: relative;
            padding-left: 35px;

            p {
                margin: 0;
                color: $text-blue;
            }

            &:before {
                position: absolute;
                left: 0;
                font-size: 22px;
            }
        }
    }
}
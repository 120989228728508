.c-action-block {
    position: relative;
    left: 0;
    padding: 10px 0;
    margin: -130px 12px 0 0;
    height: auto;
    background-color: #30ab7a;
    @include border-radius(10px);
    cursor: pointer;

    @include media-breakpoint-up(lg) {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        width: 100%;
        padding: 0 30px;
        margin: -180px 0 0 0;
    }

    &__action {
        margin: 0 30px 0 0;
        padding: 25px 50px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;

        @include media-breakpoint-up(lg) {
            width: 25%;
            padding: 32px 30px 32px 0;
        }

        &.c-action--third {
            @include media-breakpoint-up(lg) {
                margin: 0;
            }
        }
    }

    &__contact {
        flex: 1 1 auto;
        padding: 30px 48px;
        color: #FFF;
        text-align: center;
        font-size: 14px;

        @include media-breakpoint-up(lg) {
            width: 25%;
            align-content: stretch;
            padding: 25px 0px 0 30px;
        }
    }

    &__contact-text {
        display: block;
        margin: 0 0 6px 0;
        white-space: nowrap;

        @include media-breakpoint-up(lg) {
            display: block;
            width: 100%;
        }
    }

    &__contact-image {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            width: 60px;
            height: 60px;
            background: linear-gradient(52deg, #29ac79, #113d83);
            -moz-transform: translate(50%, -50%);
            -webkit-transform: translate(50%, -50%);
            -o-transform: translate(50%, -50%);
            -ms-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
            @include border-radius(50%);
        }
    }

    &__contact_thumbnail {
        display: none;

        @include media-breakpoint-up(lg) {
            position: relative;
            top: 1px;
            left: 1px;
            display: block;
            width: 58px;
            height: 58px;
            @include border-radius(50%);
        }
    }

    @include media-breakpoint-up(lg) {
        &.c-action-block--three-columns {
            .c-action-block__action,
            .c-action-block__contact {
                width: 33.333%;
            }

            .c-action--second {
                margin: 0;
            }
        }
    }
}

/* GX */

.c-action-block__contact-image img {
    @extend .c-action-block__contact_thumbnail;
}

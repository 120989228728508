.c-tabs {

    &__nav {
         display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        margin: 0;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid #dadada;
    }

    &__item {
        height: 100%;
        min-width: 150px;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0;
        -webkit-flex: 1 1 33.333%;
        -ms-flex: 1 1 33.333%;
        flex: 1 1 33.333%;
        -webkit-align-self: auto;
        -ms-flex-item-align: auto;
        align-self: auto;
    }

    &__link {
        display: block;
        color: #b1b1b1;
        padding: 0 10px;
        font-size: 16px;
        text-align: center; 
        text-decoration: none;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }  

        &.is-active {
            color: #123c81;
            border-bottom: 4px solid #de4e40;
        }
    }

    &__panels {

    }
    
    &__panel {
        display: none;
        
        &.is-active {
            display: block;
        }
    }
}

/* XC */

.c-tabs > .u-horizontal-scroll {
    margin: 0 -10px;

    @include media-breakpoint-up(sm) {
        margin: 0;
    }  
}

.c-tabs .c-tabs__nav .c-tabs__item a {
    @extend .c-tabs__link;
}

.c-tabs .c-tabs__item.is-active a {
    @extend .c-tabs__link.is-active;
}
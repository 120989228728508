.o-group {

}

.o-group__title {
    font-family: 'GT Walsheim Pro';
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #123c81;
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: left;
    }
}

.o-group__list{
    margin: 0;
    padding: 0;
    list-style: none;
}

.o-group__item {
    font-family: 'GT Walsheim Pro';
    font-size: 14px;
    line-height: 1.7;
    color: #1a1a1a;

    > i.fa {
        color: #30ab7a;
    }
}

//REFACTOR

.o-group__title {
    display: block;
    margin: 0;
    padding-bottom: 20px;

    &:after {
        display: block;
        content: "";
        margin-top: 10px;
        padding-bottom: 2px;
        background: linear-gradient(to right, rgba(41, 172, 121, 0.21), #113d83 50%, rgba(41, 172, 121, 0.21));
    }
}

.o-group__list {
    padding: 20px 0;
}

.o-group__item { 
    > i.fa {
        width: 20px;
    }
}

.o-group__item-link{
    display: inline-block;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    line-height: 25px;

    &:hover {
        color: #000;
        text-decoration: none;
    }
}
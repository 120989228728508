.our-approach-title {
    @media (min-width: 768px) {
        margin-top: 45px;
    }
}

.our-approach-body {
    p:last-child{
        margin-bottom: 0;
    }
}
.c-rating-block {
    &__top-block {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 144px;
        padding: 16px;
        border-radius: 10px;
        background: $color-green;

        @include media-breakpoint-up(sm) {
            padding: 24px;
            margin: 0 10px;
        }

        &__text,
        &__text p {
            margin: 0;
            color: #fff;
            font-size: 16px;
            line-height: 1.36;

            @include media-breakpoint-up(sm) {
                font-size: 22px;
            }
        }
    }

    &__bottom-block {
        max-height: 140px;
        margin: -23px 10px 0;
        padding: 40px 10px 22px;
        border-radius: 10px;
        background: #fff;

        @include media-breakpoint-up(sm) {
            margin: -23px 0 0 0;
            padding: 40px 24px 24px;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: auto;
        }

        &__number,
        &__number p {
            margin: 20px 0 0;
            font-size: 40px;
            font-weight: 500;
            line-height: 0.75;
            color: $text-blue;
            text-align: center;
        }
    }
}
.c-links-group {

    &__title {
        max-width: 250px;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            max-width: 100%;

        }
    }

    &__list {
        padding: 0;
        max-width: 250px;
        margin: 0 auto;

        ul {
            padding: 0 16px;
            @include columns(1);
        }

        @include media-breakpoint-up(md) {
        max-width: 100%;

            ul {
                padding: 0;
                margin-left: 0;
                margin-right: 0;
                @include columns(2);
            }
        }
    }
}

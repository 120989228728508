.c-login {

    &__background {
        display: none;
        position: absolute;
        top: 61px;
        right: -40px;
        z-index: 290;

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }

    &__ij {
        height: 200px;
        overflow: hidden;

        &::before {
            content: 'ij';
            display: block;
            margin-top: -115px;
            font-size: 200px;
            font-weight: 500;
            color: $text-blue-light;
        }
    }

    &__eyes {
        &::before,
        &::after {
            content: ' ';
            position: absolute;
            top: -25px;
            width: 23px;
            height: 23px;
            background: $color-green;
            border-radius: 50%;
        }

        &::before {
            left: 13px;
        }

        &::after {
            right: 13px;
        }

        &.eyes-closed {
            &::before,
            &::after {
                height: 3px;
                top: -15px;
            }
        }
    }

    &__block {
        position: relative;
        margin-top: 80px;
        padding: 50px 10px 30px;
        border: 1px solid $border;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
        z-index: 300;

        @include media-breakpoint-up(sm) {
            padding: 50px;
        }
    }

    &__title {
        margin-bottom: 23px;
    }

    &__input-block {
        @extend %bovemij-icon;
        position: relative;
        height: 60px;
        margin: 0 36px 20px 0;
        border: 1px solid $color-green;
        border-radius: 10px;

        @include media-breakpoint-up(sm) {
            margin-right: 60px;
        }

        &.valid,
        &.invalid {
            &::before {
                position: absolute;
                right: -28px;
                bottom: 15px;
                font-size: 20px;
            }
        }

        &.valid {
            @extend .icon-check;
        }

        &.invalid {
            @extend .icon-cross;
            color: $primair-default;
        }
    }

    &__input-title {
        width: auto;
        margin: 0 0 0 25px;
        padding: 0 10px;
        font-size: 12px;
        color: $text-blue-light;
    }

    &__input {
        width: 100%;
        padding: 5px 24px;
        border: 0;
        background: transparent;

        &[type="password"] + .c-login__pw-eye {
            @extend .icon-password-hidden;
        }

        &[type="text"] + .c-login__pw-eye {
            @extend .icon-password-shown;
        }

        &:focus {
            outline: 0 none;
        }
    }

    &__pw-eye {
        @extend %bovemij-icon;
        position: absolute;
        bottom: 9px;
        right: 10px;
        border: 0;
        background: transparent;
        cursor: pointer;

        &::before {
            color: $text-blue-light;
            font-size: 30px;
        }

        &:focus {
            outline: 0 none;
        }
    }

    &__button {
        margin: 30px 0 25px;

        button {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    &__bottom-block {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $border;
        padding-top: 10px;
    }

    &__link,
    &__link a {
        font-size: 12px;
        text-decoration: none;
        color: $text-blue-light;
    }
}
.c-contact-info-block {
    position: relative;
    margin-bottom: 30px;
    padding-left: 35px;

    h3 {
        margin: 0;
    }

    p {
        margin-bottom: 0;
    }

    &::before {
        position: absolute;
        left: 0;
        font-size: 24px;
        color: $text-blue;
    }
}
.c-nav-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 90px;
    background-color: #0a5f55;
    z-index: 99999;

    @include media-breakpoint-up(lg) {
        height: 60px;
    }

    &.is-hidden {
        display: none;
        visibility: hidden;
    }

    &.is-visible {
        display: block;
        visibility: visible;
    }
}

.c-nav-footer .row:after {
    content: "";
    width: 516px;
    height: 51px;
    position: absolute;
    top: -51px;
    right: -480px;
    background-image: url(../../img/bg_menu.png);
    background-repeat: no-repeat;
}
.u-visible {
    &\@mobile{
        @extend .hidden-md-up;
    }

    &\@desktop{
        @extend .hidden-md-down;
    }

    &\@tablet-and-up {
        @extend .hidden-sm-down;
    }
}

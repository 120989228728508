.c-primary-nav {
    &__list-heading {
        color: #FFF;
        font-size: 18px;
        font-weight: 500;
        padding-left: 34px;
    }

    &__list {
        margin: 0 0 50px;
        padding: 0 0 0 10px;
        list-style: none;
    }

    &__item {
        display: block;
        font-size: 14px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item-link {
        position: relative;
        display: block;
        color: #FFF;
        text-decoration: none;
        text-transform: none;
        padding: 0 0 0 20px;

        &:hover {
            text-decoration: underline;
        }

        &:before {
            position: absolute;
            top: 3px;
            left: 0;
            content: "\f178";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}

/* GX */

.block-klantenbinden h2,
.block-risicoosverzekeren h2,
.block-risocosverkleinen h2, 
.block-nieuweactiviteiten h2 {
    position: relative;
    margin: 0 0 10px 0;
    @extend .c-primary-nav__list-heading;
    
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        color: #FFF !important;
        font-size: 24px;
    }
}

.block-klantenbinden h2:before {
    @include bovemij-icon(nav-klantenbinden);
}

.block-risicoosverzekeren h2:before {
    @include bovemij-icon(verzekeren);
}

.block-risocosverkleinen h2:before {
    @include bovemij-icon(risicomanagement);
}

.block-nieuweactiviteiten h2:before {
    @include bovemij-icon(nav-nieuweactiviteiten);
}

.block-klantenbinden ul,
.block-risicoosverzekeren ul,
.block-risocosverkleinen ul, 
.block-nieuweactiviteiten ul {
    @extend .c-primary-nav__list;
}

.block-klantenbinden ul li.item,
.block-risicoosverzekeren ul li.item,
.block-risocosverkleinen ul li.item,
.block-nieuweactiviteiten ul li.item {
    @extend .c-primary-nav__item;
}

.block-klantenbinden ul li a,
.block-risicoosverzekeren ul li a,
.block-risocosverkleinen ul li a,
.block-nieuweactiviteiten ul li a {
    @extend .c-primary-nav__item-link;
}
.c-content-metadata {
    
    &__author {
        position: relative;
    }
    
    &__name {
        position: absolute;
        top: 0;
        left: 50px;
        color: #0a519d;
        font-size: 14px;
        font-weight: bold;

        &:before {
            display: block;
            content: "Door:";
            font-size: 12px;
            font-weight: 300;
            color: #191919;
        }
    }

    &__thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        width: 38px;
        height: 38px;
        margin: 0 10px 0 0;
        overflow: hidden;
        background: linear-gradient(52deg, #29ac79, #113d83);
        @include border-radius(50%);

        &:after{
            content: "";
            position: absolute;
            top: 1px;
            left: 1px;
            width: 36px;
            height: 36px;
            @include border-radius(50%);
            z-index: 1;
        }
    }

    &__thumbnail-image {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 36px;
        height: 36px;
        background-color: #FFF;
        z-index: 2;
        @include border-radius(50%);
    }

    &__date {
        float: right;
        font-size: 14px;
        color: #191919;
        position: relative;
        padding-left: 22px;
        text-align: right;

        &:before {
            display: block;
            content: "Datum:";
            font-size: 12px;
            font-weight: 300;
            color: #191919;
        }

        &:after {
            @include bovemij-icon(calendar);
            position: absolute;
            top: 20px;
            left: 0;
            color: #0a519d;
            font-size: 15px;
            margin: 0 5px 0 0;
        }
    }
}

/* XC */
.c-content-metadata .c-content-metadata__thumbnail img {
    @extend .c-content-metadata__thumbnail-image;
}
.link--arrow a,
a.link--arrow {
    color: $text-black;
    text-decoration: none;
    line-height: 25px;

    &:hover {
        color: $text-black;
    }

    &::before {
        color: $color-green;
        text-decoration: none;
        margin-right: 10px;
        content: "\f178";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 14px;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}
.c-masonary {

    &__grid {
        min-width: 300px;
        margin: 0 -10px;
    }

    &__item {
        width: 100%;
        margin-bottom: 50px;

        @include media-breakpoint-up(md) {
            width: 50%;
        }

        @include media-breakpoint-up(lg) {
            width: 33.333%;
        }
    }
}

/* XC */

.c-masonary .wrapper {
    @extend .c-masonary__grid;
}

.c-masonary  .wrapper .item {
    @extend .c-masonary__item;
}
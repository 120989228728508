.c-image {
    &__body {
        position: relative;
        width: 100%;
        margin: 0 0 20px 0;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__title {
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        color: #0a5f55;
    }
}
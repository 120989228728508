.c-ask-a-question {
    max-width: 380px;

    &__title {
        font-size: 16px;
        font-weight: 500;
        color: #123c81;
    }

    &__list {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        padding: 10px 0;
        background-color: rgba(48, 171, 122, 0.8);
        @include border-radius(10px);
        height: 52px;
    }

    &__list-item {
        position: relative;
        flex: 1 0 16.667%;
        text-indent: -99999px;
        overflow: hidden;
        border-right: 1px solid #FFF;

        &:before{
            content: "";
            display: block;
            padding-top: 50%;
        }

        &:last-child {
            border-right: 0 none;
        }

        a {
            text-decoration: none;
        }
    }

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:before{
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 21px;
            color: #FFF;
            text-indent: 0;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }
}

/* GX */

.c-ask-a-question__list-item a {
    @extend .c-ask-a-question__link;
}

.c-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    background-color: #FFF;
    z-index: 999;

    @include media-breakpoint-up(lg) {
        height: 80px;
        left: -17px;
        margin-left: calc(100vw - 100%);
    }

    &.has-visible-menu{
        height: auto;
        bottom: 0;

        &:before, &:after{
            display: block;
        }
    }

    &__logo {
        position: fixed;
        width: 116px;
        height: 29px;
        top: 12px;
        left: 50%;
        overflow: hidden;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 23px;
            left: 10px;
            width: 144px;
            height: 35px;
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
    }

    &__logo-image{
        display: block;
        width: 116px;
        height: 29px;

        @include media-breakpoint-up(lg) {
            width: 144px;
            height: 35px;
        }
    }

    &__primary-nav-toggle-button {
        position: absolute;
        top: 9px;
        left: 10px;
        width: 32px;
        height: 32px;
        cursor: pointer;
        font-size: 16px;
        color: #1a1a1a;
        padding-left: 50px;
        
        &:before {
            position: absolute;
            top: 2px;
            left: 2px;
            font-size: 28px;
            line-height: 1;
            @include bovemij-icon(menu);
            color: #0a519d;
        }

        @include media-breakpoint-up(lg) {
            top: 25px;
            left: 175px;
            padding-left: 30px;

            &:before {
                top: 6px;
                font-size: 18px;
            }
        }

        &.has-visible-menu {
            &:before {
                @include bovemij-icon(close);
                color: #0a519d;
            }
        }
    }

    &__links {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    &__secondary-nav{
        @include media-breakpoint-up(lg) {
            -webkit-flex: 2 1 66.666%;
            -ms-flex: 2 1 66.666%;
            flex: 2 1 66.666%;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            padding: 23px 0;
        }   
    }



    &__id {
        position: relative;
        padding: 0 0 0 55px;

        @include media-breakpoint-up(sm) {
            padding: 0 0 0 64px;
        }

        @include media-breakpoint-up(lg) {
            -webkit-flex: 1 1 33.333%;;
            -ms-flex: 1 1 33.333%;;
            flex: 1 1 33.333%;;
            -webkit-align-self: auto;
            -ms-flex-item-align: auto;
            align-self: auto;
            background: #30ab7a;
            width: 33.333%;
            padding: 0 0 0 84px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 732px;
            height: 50px;
            background: #30ab7a;
            z-index: 1;

            @include media-breakpoint-up(lg) { 
                height: 80px;
            }
        }

        &:after {
            content: "";
            width: 96px;
            height: 80px;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url('../../img/bg_header-mobile.png');
            background-repeat: no-repeat;
            z-index: 2;

            @include media-breakpoint-up(lg) { 
                background-image: url('../../img/bg_header-desktop.png');
            }
        }
    }

    &__id-dropdown {
        position: relative;
        z-index: 3;

        @include media-breakpoint-up(lg) { 
            width: 186px;
            height: 80px;
            padding: 18px 0;
        }
    }

    &__id-company-type {
        display: none;

        @include media-breakpoint-up(lg) { 
            display: block;
            color: #FFF;
            padding: 0 0 0 45px;
        }
        
        span{
            display: block;
        }
        
        span:first-child{
            font-size: 13px;
            margin: 0 0 5px 0;
        }

        span:last-child,
        span.username{
            padding-right: 28px;
            font-size: 10px;
            text-decoration: underline;
            height: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    [class^="icon-"] {
        position: absolute;
        color: #FFF;
        font-size: 30px;
    }

    &__id-label {
        display: none;

        @include media-breakpoint-up(lg) { 
            display: block;
            position: absolute;
            top: 0;
            left: 42px;
            width: calc(100% - 42px);
            height: 100%;
            margin: 0;
            cursor: pointer;

            &:before {
                position: absolute;
                bottom: 18px;
                right: 0;
                width: 20px;
                height: 20px;
                font-size: 14px;
            }
        }
    }

    &__id-dropdown-content {
        @include media-breakpoint-down(md) { 
            display: none !important;
            visibility: hidden;
        }

        @include media-breakpoint-up(lg) { 
            top: 70px;
            width: 220px;
        }
    }
}

/* GX */

#header-wrapper{
    @extend .c-header;
}

#header-wrapper.has-visible-menu{
    @extend .c-header.has-visible-menu;
}

#header-wrapper .c-header__logo img{
    @extend .c-header__logo-image;
}

#header-wrapper .hamburgermenu{
    @extend .c-header__primary-nav-toggle-button;
    
    .closed, .opened {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        .closed, .opened {
            line-height: 30px;
            font-size: 16px;
            color: #1a1a1a;
        }
        
        .closed {
            display: block;
        }
        .opened {
            display: none;
        }

        &.has-visible-menu {
            .closed {
                display: none;
            }
            .opened {
                display: block;
            }
        }
    }
}

.c-header__id.c-header__id--login .c-header__id-icon {
    position: absolute;
    width: 40px;
    height: 48px;
    left: 10px;

    @include media-breakpoint-up(lg) {
        height: 40px;
    }

    &:after {
        content: "\f007";
        display: inline-block;
        font-family: FontAwesome;
        font-size: 26px;
        line-height: 48px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #FFF;

        @include media-breakpoint-up(lg) {
            line-height: 40px;
        }
    }

    a {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}


#container-wrapper {
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
}

#main-wrapper {
    margin-top: 50px;
    overflow: hidden;
    padding-left: 0 ;
    padding-right: 0;

    @include media-breakpoint-up(lg) {
        margin-top: 80px;
    }
}

a {
    color: #30ab7a;
    text-decoration: underline;
}

/* Boostrap 4 Grid additions */

.row.last-row{
    position: relative;
    padding-top: 50px;
    padding-bottom: 5px;
    background: -webkit-linear-gradient(to bottom, #e8e8e8, #f1f1f1);
    background: -moz-linear-gradient(to bottom, #e8e8e8, #f1f1f1);
    background: -o-linear-gradient(to bottom, #e8e8e8, #f1f1f1);
    background: linear-gradient(to bottom, #e8e8e8, #f1f1f1);

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: -webkit-linear-gradient(89deg, #29ac79, #113d83);
        background: -moz-linear-gradient(89deg, #29ac79, #113d83);
        background: -o-linear-gradient(89deg, #29ac79, #113d83);
        background: linear-gradient(89deg, #29ac79, #113d83);
    }

    .container > .row:last-child {
        &:before {
            content: "";
            position: absolute;
            right: 0;
            bottom: -5px;
            width: 1920px;
            height: 39px;
            margin: 0 -480px;
            background-image: url(../../img/bg_footer.png);
            background-repeat: no-repeat;
            background-position: bottom right;
            z-index: 99;
        }
    }
}

.row.last-row--no-bg {
    background: none;
}

#main .container > .row {
    margin-bottom: 75px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 100px;
    }
}

#column-1 {
    margin-bottom: 75px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 100px;
    }

    + #column-2 {
        margin-top: 0px;
    }
}

/* TODO: REFACTOR FOR BANNER LESS PAGES  */

#column-2 {
    margin-top: 30px;

    @include media-breakpoint-up(lg) {
        margin-top: 60px;
    }
}

/* Buttons */

$btn-tertiary-color: #191919;
$btn-tertiary-bg: #FFF;
$btn-tertiary-border: #dadada;

.btn.btn {
    height: auto;
    min-height: 40px;
    line-height: 1.5;
    white-space: normal;

    &~ .btn {
        margin-left: 20px;
    }

    &.btn-tertiary {
        @include button-variant($btn-tertiary-color, $btn-tertiary-bg, $btn-tertiary-border);
    }
}

/* Iframe */
iframe {
    width: 100%;
}

/* Hacks & Fixes */

/* IE fix for wbr */
wbr:after { content: "\00200B"; }
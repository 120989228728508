.c-tip {

    &__heading {
        width: 100%;
        height: 46px;
        line-height: 46px;
        color: #fff;
        text-align: center;
        background: #30AB7A;
        margin: 0 0 30px 0;
        @include border-top-radius(10px);
        overflow: hidden;
    }

    &__body {
        padding: 0 20px 20px;
    }

    &__title {
        margin: 0 0 20px 0;
    }

    &__link {
        color: #191919;
        margin: 0 0 20px 0;

        &:before {
            color: #30ab7a;
            margin-right: 10px;
            content: "\f178";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    &__meta {
        padding-top: 20px;
        border-top: 1px solid #ececec;
        @include clearfix;
    }

    &__name {
        position: relative;
        float: left;
        padding: 16px 0 0 0;
        color: #0a519d;
        font-size: 14px;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "Door";
            font-size: 12px;
            color: #191919;
        }
    }

    &__date {
        float: right;
        font-size: 14px;
        width: 55px;
        font-size: 16px;
        width: auto;
        color: #191919;
        padding: 16px 0 0 0;

        &:before {
            position: absolute;
            right: 0;
            top: 0;
            content: "Datum";
            font-size: 12px;
            color: #191919;
        }

        &--icon {
            position: relative;
            padding-left: 22px;

            &:after {
                position: absolute;
                top: 18px;
                left: 0;
                @include bovemij-icon(calendar);
                color: #0a519d;
                font-size: 15px;
                margin: 0 5px 0 0;
            }
        }
    }

    &__thumbnail {
        float: left;
        position: relative;
        width: 38px;
        height: 38px;
        margin: 0 10px 0 0;
        overflow: hidden;
        background: linear-gradient(52deg, #29ac79, #113d83);
        @include border-radius(50%);

        &:after{
            content: "";
            position: absolute;
            top: 1px;
            left: 1px;
            width: 36px;
            height: 36px;
            background-color: #FFF;
            @include border-radius(50%);
            z-index: 1;
        }
    }

    &__thumbnail-image {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 36px;
        height: 36px;
        background-color: #FFF;
        z-index: 2;
    }
}

/* GX */

.c-tip__heading * {
    height: 46px;
    line-height: 46px;
    color: #fff;
}

.c-tip__link * {
    display: inline;
    color: #191919;
}

.c-tip__thumbnail img {
    @extend .c-news-item__thumbnail-image;
}
.btn{
    height: 40px;
    display: inline-block;
    @extend %gtwalsheim-medium;
    // color: $text-black;
    text-decoration: none;
    font-size: 1rem;
    // background: #fff;
    // border:1px solid $border;
    text-align: center;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 5px;
    box-sizing: border-box;
    transition: all 0.2s ease;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.13);
    cursor: pointer;

    &.disabled{
        cursor: not-allowed;
        pointer-events: none;
    }


    &.btn-secondary{
        background: $tertiriar-default;
        border: none;

        &:hover{
            background:#fff;
        }

        &:active{
            background: #ECECEC;
        }
    }

    &.primary{
        background: $primair-default;
        border:none;
        color:#fff;

        &:hover{
            background: $primair-hover;
        }

        &:active{
            background: $primair-focus;
        }

        &.disabled{
            color:$primair-disabled-text;
            background: $primair-disabled;
        }
    }

    &.secundary{
        background: $tertiriar-default;
        border:none;
        color:#fff;

        &:hover{
            background: $primair-hover;
        }

        &:active{
            background: $primair-focus;
        }

        &.disabled{
            color:$primair-disabled-text;
            background: $primair-disabled;
        }
    }
}

.c-news-list {
    &__item {
        position: relative;
        margin: 0 0 15px 0;
        padding: 0 0 0 50px;
    }

    &__date {
        position: absolute;
        left: 0;
        top: 0;
        color: #0A519D;
        width: 50px;
        padding: 0;
        font-weight: 300;

        &:before {
            display: none;
        }
    }

    &__link {
        color: #191919;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

/* XC */

.c-news-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.c-news-list ul li.item {
    @extend .c-news-list__item;
}

.c-news-list ul li.item time {
    @extend .c-news-list__date;
}

.c-news-list ul li.item a {
    @extend .c-news-list__link;
}
.u-slide-in-from-right {
    -webkit-animation: slideInFromRight 2s;
    -moz-animation: slideInFromRight 2s;
    -o-animation: slideInFromRight 2s;
    animation: slideInFromRight 2s;
}

@-webkit-keyframes slideInFromRight {
  0%   { transform: translateX(100%); }
  100% { transform: translateX(0) }
}
@-moz-keyframes slideInFromRight {
  0%   { transform: translateX(100%); }
  100% { transform: translateX(0) }
}
@-o-keyframes slideInFromRight {
  0%   { transform: translateX(100%); }
  100% { transform: translateX(0) }
}
@keyframes slideInFromRight {
  0%   { transform: translateX(100%); }
  100% { transform: translateX(0) }
}
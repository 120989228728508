$font-path: "../../webfonts/";

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('#{$font-path}GT-Walsheim-Pro-Thin.eot');
    src: url('#{$font-path}GT-Walsheim-Pro-Thin.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}GT-Walsheim-Pro-Thin.woff') format('woff'),
    url('#{$font-path}GT-Walsheim-Pro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('#{$font-path}GT-Walsheim-Pro-Thin-Oblique.eot');
    src: url('#{$font-path}GT-Walsheim-Pro-Thin-Oblique.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}GT-Walsheim-Pro-Thin-Oblique.woff') format('woff'),
    url('#{$font-path}GT-Walsheim-Pro-Thin-Oblique.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('#{$font-path}GT-Walsheim-Pro-Regular-Oblique.eot');
    src: url('#{$font-path}GT-Walsheim-Pro-Regular-Oblique.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}GT-Walsheim-Pro-Regular-Oblique.woff') format('woff'),
    url('#{$font-path}GT-Walsheim-Pro-Regular-Oblique.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'GT Walsheim Pro Ultra';
    src: url('#{$font-path}GT-Walsheim-Pro-Ultra-Light-Oblique.eot');
    src: url('#{$font-path}GT-Walsheim-Pro-Ultra-Light-Oblique.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}GT-Walsheim-Pro-Ultra-Light-Oblique.woff') format('woff'),
    url('#{$font-path}GT-Walsheim-Pro-Ultra-Light-Oblique.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('#{$font-path}GT-Walsheim-Pro-Regular.eot');
    src: url('#{$font-path}GT-Walsheim-Pro-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}GT-Walsheim-Pro-Regular.woff') format('woff'),
    url('#{$font-path}GT-Walsheim-Pro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('#{$font-path}GT-Walsheim-Pro-Medium.eot');
    src: url('#{$font-path}GT-Walsheim-Pro-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}GT-Walsheim-Pro-Medium.woff') format('woff'),
    url('#{$font-path}GT-Walsheim-Pro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('#{$font-path}GT-Walsheim-Pro-Light-Oblique.eot');
    src: url('#{$font-path}GT-Walsheim-Pro-Light-Oblique.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}GT-Walsheim-Pro-Light-Oblique.woff') format('woff'),
    url('#{$font-path}GT-Walsheim-Pro-Light-Oblique.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('#{$font-path}GT-Walsheim-Pro-Medium-Oblique.eot');
    src: url('#{$font-path}GT-Walsheim-Pro-Medium-Oblique.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}GT-Walsheim-Pro-Medium-Oblique.woff') format('woff'),
    url('#{$font-path}GT-Walsheim-Pro-Medium-Oblique.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'GT Walsheim Pro Ultra';
    src: url('#{$font-path}GT-Walsheim-Pro-Ultra-Light.eot');
    src: url('#{$font-path}GT-Walsheim-Pro-Ultra-Light.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}GT-Walsheim-Pro-Ultra-Light.woff') format('woff'),
    url('#{$font-path}GT-Walsheim-Pro-Ultra-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'GT Walsheim Pro';
    src: url('#{$font-path}GT-Walsheim-Pro-Light.eot');
    src: url('#{$font-path}GT-Walsheim-Pro-Light.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}GT-Walsheim-Pro-Light.woff') format('woff'),
    url('#{$font-path}GT-Walsheim-Pro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

%gtwalsheim-normal{
    font-family: GT Walsheim Pro;
    font-weight: normal;
}

%gtwalsheim-thin{
    font-family: GT Walsheim Pro;
    font-weight: 100;
}

%gtwalsheim-ultra{
    font-family: GT Walsheim Pro Ultra;
    font-weight: 200;
}

%gtwalsheim-light{
    font-family: GT Walsheim Pro;
    font-weight: 200;
}

%gtwalsheim-medium{
    font-family: GT Walsheim Pro;
    font-weight: 500;
}

.c-secondary-nav {
    &\@mobile {
        display: block;
        width: 100%;
        height: 90px;
        padding: 0 20px;
        visibility: visible;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            display: none;
            visibility: hidden;
        }
    }

    &\@desktop {
        display: none;
        visibility: hidden;

        @include media-breakpoint-up(lg) {
            display: block;
            height: 80px;
            visibility: visible;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: right;       
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        height: 90px;
        padding: 15px 0;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
            margin-left: 30px;

            &:before {
                content: "";
                position: absolute;
                top: 15px;
                left: -30px;
                width: 1px;
                height: 60px;
                border-style: solid;
                border-width: 1px;
                border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 50%, rgba(255, 255, 255, 0));
                border-image-slice: 1;
            }
        }

        @include media-breakpoint-up(lg) {
            height: 34px;
            margin: 0;
            padding: 0 20px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding: 0;
                margin-left: 0;

                &:before {
                    content: none;
                }
            }
        }
    }

    &__item-link {
        position: relative;
        width: auto;
        height: 60px;
        font-family: "GT Walsheim Pro";
        color: #FFF;
        text-decoration: none;
        display: table-cell;
        vertical-align: bottom;
        text-align: center;

        &:hover, &:focus {
            color: #FFF;
            text-decoration: none;
        }

        &:before {
            position: absolute;
            top: 0;
            left: 50%;
            color: #FFF !important;
            font-size: 24px;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        }

        @include media-breakpoint-up(lg) {
            height: 34px;
            font-size: 16px;
            color: #1a1a1a;
            line-height: 34px;
            text-align: left;
            display: block;
            vertical-align: baseline;

            &:hover, &:focus {
                color: #1a1a1a;
                text-decoration: none;
            }

            &:before {
                position: relative;
                top: auto;
                left: auto;
                color: #b1b1b1 !important;
                font-size: 18px;
                margin-right: 8px;
                -webkit-transform: translateX(0);
                -moz-transform: translateX(0);
                -ms-transform: translateX(0);
                transform: translateX(0);
            }
        }

        &--search {
            &:before {
                @include bovemij-icon(search);
            }

            @include media-breakpoint-up(lg) {
                width: auto;
                margin-right: 0;
            }
        }

        &--contact {
            &:before {
                @include bovemij-icon(phone);
            }
        }

        &--login,
        &--logout {
            &:before {
                @include bovemij-icon(user);
            }

            @include media-breakpoint-up(lg) {
                height: 34px;
                padding-left: 20px;
                padding-right: 20px;
                border-left: 3px solid #dadada;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    display: block;
                    left: 20px;
                    width: 109px;
                    height: 34px;
                    background: url(../../img/login-underline.svg) bottom no-repeat;
                }
            }
        }
    }
}

/* GX */

.c-secondary-nav__list ul{
    @extend .c-secondary-nav__list;
}

.c-secondary-nav__list ul li {
    @extend .c-secondary-nav__item;
    
    &:last-child {
        @include media-breakpoint-up(lg) { 
            display: none;
        }
    }
}

.c-secondary-nav\@mobile .block-default,
.c-secondary-nav\@mobile .block-default .pagesection-default,
.c-secondary-nav\@mobile .block-default .pagesection-default .wrapper,
.c-secondary-nav\@mobile .block-default .pagesection-default .wrapper .editableContainer {
    height: 100%;
}

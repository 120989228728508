.c-news-block {
    &__news-item {
        height: 100%;
    }

    &__item-summary {
        &--list-item {
            top: 0;
            padding: 0;
            margin-bottom: 15px;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

/* GX */

.c-news-block .wrapper {
     @include make-row();
}

.c-news-block .wrapper .item {
    @include make-col(12);
    @include make-gutters();

    @include media-breakpoint-up(sm) {
        @include make-col(6);
    }
    
    &:nth-child(1) {
        .c-news-block__item-summary--list-item{
            display: none;
        }
    }

    &:nth-child(2){
        .c-news-block__item-summary--list-item{
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }
    }

    &:nth-child(1), &:nth-child(2) {
        margin-bottom: 15px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 0
        }
    }

    &:nth-child(n+3){
        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &:nth-child(n+2) {
        .c-news-block__background,
        .c-news-block__item-summary {
            display: none;

            @include media-breakpoint-up(sm) {
                display: block;
            }
        }
    }
}

.row.last-row .c-news-block {
    margin-bottom: 127px;
}
$fa-font-path: '../../webfonts';

$color-white: #fff;
$color-yellow: #FEDC37;
$color-green: #30AB7A;
$color-border: #ECECEC;

$border: #DADADA;

$text-black: #191919;
$text-blue: #123C81;
$text-blue-light: #0a519d;
$text-green: $color-green;

$icon-base: $color-green;

$tertiriar-default: $color-yellow;

$primair-default: #DE4E40;
$primair-focus: #B42517;
$primair-hover: #CA3B2D;
$primair-disabled: #ECECEC;
$primair-disabled-text:#C3C3C3;

// Boostrap variables

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 960px
);

$grid-gutter-width-base: 20px;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
);

$btn-primary-color: #FFF;
$btn-primary-bg: #de4e40;
$btn-primary-border: #de4e40;

$btn-secondary-color: #000;
$btn-secondary-bg: #fedc37;
$btn-secondary-border: #fedc37;

.c-employees-speaking {
    &__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;

        &.is-hidden {
            display: none;
        }
    }

    &__background {
        position: relative;
    }

    &__image {
        overflow: hidden;
        @include border-radius(10px);
    }

    &__block {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        margin: -20px 10px 0;
        padding: 25px 15px;
    }

    &__title {
        color: #123c81;
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 15px 0;
    }

    &__link {
        margin: 0;
    }

    &__meta {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px 25px 30px;
        background-color: rgba(26, 26, 26, 0.4);
        @include border-bottom-radius(10px);
    }

    &__name{
        color: #FFF;
        font-size: 18px;
    }
    
    &__profession {
        color: #FFF;
        font-size: 14px;
    }
}

/* XC */

.c-employees-speaking .wrapper {
     @include make-row();
}

.c-employees-speaking .wrapper .item {
    @include make-col(12);
    @include make-gutters();

    &:not(:first-child){
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    @include media-breakpoint-up(md) {
        @include make-col(6);
    }
}

.c-employees-speaking .c-employees-speaking__image img {
    display: block;
    width: 100%;
    height: auto;
}

.c-employees-speaking .c-employees-speaking__read-more a {
    @extend .c-employees-speaking__link;
}

/* IE10 and IE11 hacks */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.c-employees-speaking__item {
        display: block; /* Disable flexbox to avoid IE flexbox height bug */
    }
}
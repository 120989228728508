.c-checkbox {
    position: relative;
    width: 100%;
    height: 65px;
    padding: 0 10px;
    vertical-align: middle;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        height: 50px;
    }
    
    &__input {
        position: absolute;
        left: -9999px;
    }

    &__label {
        position: absolute;
        top: 50%;
        width: 90%;
        height: auto;
        min-height: 24px;
        margin: 0;
        padding: 3px 0 3px 34px;
        cursor: pointer;
        display: inline-block;
        color: #000;
        font-size: 16px;
        z-index: 9;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);

        @include media-breakpoint-up(md) {
            font-size: 12px;
        }

        &:before, &:after {
            position: absolute;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }

        &:before {
            content: '';
            left: 0;
            top: 50%;
            width: 24px;
            height: 24px;
            border: 1px solid #c3c3c3;
            background: #ececec;
        }

        &:after {
            top: 50%;
            left: 5px;
            width: 14px;
            height: 14px;
            @include bovemij-icon(check);
            font-size: 14px;
            font-weight: bold;
        }
    }

    &__input:not(:checked) ~ &__label {
        &:after {
            opacity: 0;
            -webkit-transform: translateY(-50%), scale(0);
            -moz-transform: translateY(-50%), scale(0);
            -ms-transform: translateY(-50%), scale(0);
            -o-transform: translateY(-50%), scale(0);
            transform: translateY(-50%), scale(0);
        }
    }

    &__input:checked ~ &__label {
        opacity: 1;
        -webkit-transform: translateY(-50%), scale(1);
        -moz-transform: translateY(-50%), scale(1);
        -ms-transform: translateY(-50%), scale(1);
        -o-transform: translateY(-50%), scale(1);
        transform: translateY(-50%), scale(1);
    }

    &__input:not(:checked) ~ &__background {
        position: relative;

        @include media-breakpoint-up(md) {
            position: static;
        }

        &:before {
            background: #FFF;

            @include media-breakpoint-up(md) {
                background: #d2d2d5;
                border-bottom: 0 none;
            }
        }
    }

    &__input:checked ~ &__background {
        &:before {
             background: -webkit-linear-gradient(76deg, #29ac79, #113d83);
             background: -moz-linear-gradient(76deg, #29ac79, #113d83);
             background: -ms-linear-gradient(76deg, #29ac79, #113d83);
             background: -o-linear-gradient(76deg, #29ac79, #113d83);
             background: linear-gradient(76deg, #29ac79, #113d83);
        }
    }

    &__background {
        width: 100%;
        height: 100%;
        border-top: 1px solid #ececec;

        @include media-breakpoint-up(md) {
            border-bottom: 0 none;
        }

        &:before, &:after {
            content: "";
            position: absolute;
        }

        &:before{
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 10px;
        }

        &:after {
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            background: #FFF;
            border-radius: 8px;
        }
    }

    &--first &__background {
        border-top: 0 none;
    }
}
.c-banner {
    position: relative;
    height: 294px;

    @include media-breakpoint-up(lg) {
        height: 430px;
    }

    &--large {
        height: 420px;

        @include media-breakpoint-up(lg) {
            height: 590px;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        left: 50%;
        width: 576px;
        height: 100%;
        margin: 0;
        overflow: hidden;
        z-index: -1;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);

        &:before{
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            right: -470px;
            width: 630px;
            height: 125px;
            background:#fff;
            background: url('../../img/bg_banner.png');
            background-size: auto 125px;
            background-position: left bottom;
            z-index: 9;

            @include media-breakpoint-up(md) {
                right: -345px;
            }

            @include media-breakpoint-up(lg) {
                right: 0;
            }
        }

        &:after{
            content: "";
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: -webkit-calc(100% - 160px);
            width: expression(100% - 160px);
            width: -moz-calc(100% - 160px);
            width: -o-calc(100% - 160px);
            width: calc(100% - 160px);
            height: 10px;
            background: #185E80;
            background: -moz-linear-gradient(left, #29AC79 0%, #185E80 100%);
            background: -webkit-linear-gradient(left, #29AC79 0%,#185E80 100%);
            background: linear-gradient(to right, #29AC79 0%,#185E80 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#29AC79', endColorstr='#185E80', GradientType=1);
            z-index: 8;

            @include media-breakpoint-up(md) {
                width: -webkit-calc(100% - 285px);
                width: expression(100% - 285px);
                width: -moz-calc(100% - 285px);
                width: -o-calc(100% - 285px);
                width: calc(100% - 285px);
            }

            @include media-breakpoint-up(lg) {
                width: -webkit-calc(100% - 450px);
                width: expression(100% - 450px);
                width: -moz-calc(100% - 450px);
                width: -o-calc(100% - 450px);
                width: calc(100% - 450px);
            }
        }

        @include media-breakpoint-up(sm) {
            left: 0;
            width: 768px;
            margin: 0 -114px;
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
        }

        @include media-breakpoint-up(md) {
            width: 992px;
            margin: 0 -136px;
        }

        @include media-breakpoint-up(lg) {
            width: 1920px;
            margin: 0 -480px;
        }

        &--no-border {
            padding-bottom: 0;

            &:before, &:after{
                content: none;
            }
        }
    }

    &__background-image{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &__background-video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        .oembed.oembed-youtube {
            width: 1920px !important;
            height: 590px !important;
        }

        iframe {
            display: block;
            width: 1920px;
            height: 590px;
        }
    }

    &__background-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 169px;
        background-image: url('../../img/banner_overlay-xs.png');
        background-repeat: no-repeat;
        z-index: 2;

        @include media-breakpoint-up(sm) {
            height: 169px;
            background-image: url('../../img/banner_overlay-sm.png');
        }

        @include media-breakpoint-up(md) {
            height: 159px;
            background-image: url('../../img/banner_overlay-md.png');
        }

        @include media-breakpoint-up(lg) {
            height: 235px;
            background-image: url('../../img/banner_overlay-lg.png');
        }
    }

    &__quote {
        position: relative;
        height: 251px;
        display: table-cell;
        vertical-align: bottom;

        @include media-breakpoint-up(md) {
            height: 261px;
            margin: 0 0 100px 0;
        }

        @include media-breakpoint-up(lg) {
            height: auto;
            display: block;
        }
    }

    &__quote-title {
        position: relative;
        color: #ffffff;
        font-size: 22px;
        font-weight: 300;
        color: #ffffff;
        text-shadow: 0 2px 20px rgba(0, 0, 0, 0.22);
        z-index: 1;
        line-height: 1.5;
        margin: 0 0 10px 0;

        @include media-breakpoint-up(md) {
            font-size: 32px;
        }
    }

    &__quote-text,
    &__quote-text p {
        position: relative;
        width: 100%;
        font-size: 20px;
        color: #ffffff;
        font-weight: 300;
        text-shadow: 0 2px 20px rgba(0, 0, 0, 0.22);
        z-index: 1;
        line-height: 1.25;
        margin: 0 0 100px 0;
        
        h1 {
            color: $color-white;
        }

        &--large {
            font-size: 22px;
            font-weight: 500;
            margin: 0 0 15px 0;

            @include media-breakpoint-up(lg) {
                font-size: 32px;
                margin: 0 0 20px 0;
            }
        }

        &--icon {
            &:before {
                content: "";
                position: absolute;
                top: -5px;
                left: 0;
                width: 42px;
                height: 31px;
                background-image: url('../../img/quote.png');
                background-repeat: no-repeat;
                z-index: -1;

                @include media-breakpoint-up(sm) {
                    top: -7px;
                    left: -31px;
                }

                @include media-breakpoint-up(lg) {
                    top: -1px;
                    left: -31px;
                }
            }
        }
    }

    &__name,
    &__company {
        color: #FFF;
        font-size: 16px;
    }

    &__usp {
        height: 169px;
        padding: 40px 0 20px 0;

        @include media-breakpoint-up(md) {
            height: 159px;
            padding: 35px 0 15px 0;
        }

        @include media-breakpoint-up(lg) {
            height: 235px;
            padding: 30px 0;
        }
    }

    &__usp-title {
        margin: 0;
        color: #FFF;
        font-size: 16px;
        margin: 0 0 10px 0;

        @include media-breakpoint-up(lg) {
            font-size: 28px;
            text-align: right;
            margin: 0 0 30px 0;
        }
    }

    &__usp-list {
       @include clearfix();
    }


    &__usp-item {
        position: relative;
        padding: 0 0 0 24px;
        color: #FFF;
        font-size: 12px;
        margin: 0 0 12px 0;

        @include media-breakpoint-up(lg) {
            float: right;
            font-size: 16px;
            margin: 0 0 20px -24px;

            &:nth-child(1) {
                min-width: 287px;
            }

            &:nth-child(2) {
                min-width: 330px;
            }

            &:nth-child(3) {
                min-width: 380px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            position: absolute;
            top: 2px;
            left: 0;
            @include bovemij-icon(check);
            font-size: 14px;

            @include media-breakpoint-up(lg) {
                top: 5px;
            }
        }
    }

    &__block {
        min-height: 298px;
        max-height: 520px;
        margin-bottom: -100px;
        z-index: 99;
        overflow: auto;

        @include media-breakpoint-up(lg) {
            max-height: 352px;
            margin-bottom: 50px;
        }
    }

    &__block-title {
        margin-bottom: 20px;
    }

    &__block-text li {
        @extend %bovemij-icon;
        position: relative;
        padding-left: 25px;

        &::before {
            content: '\e918';
            position: absolute;
            left: 0;
            color: $color-green;
        ;
        }
    }

    &__block-list {
        margin-bottom: 20px;
    }

    &__block-list-item {
        font-size: 14px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 10px;
        }
    }
}

/* GX */

.c-banner__background-image figure,
.c-banner__background-image picture {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
}

.c-banner__block {
    padding: 30px 15px;

    @include media-breakpoint-up(sm) {
        padding: 30px 35px;
    }
}

.c-banner__block h1 {
    color: #123c81;
    font-size: 20px;
    font-weight: 500;
}

.c-banner__block ul {
    @extend .o-list;
    @extend .c-banner__block-list;
}

.c-banner__block ul li {
    @extend .c-banner__block-list-item;
}

.c-banner__usp-title h2 {
    @extend .c-banner__usp-title;
}

.c-banner__quote-title h1,
.c-banner__quote-title div {
    @extend .c-banner__quote-title;
}

.c-usp-block {
    position: relative;
    width: 938px;
    height: 271px;
    
    &:after{
        background: -webkit-linear-gradient(347deg, #29ac79, #113d83);
        background: -moz-linear-gradient(347deg, #29ac79, #113d83);
        background: -o-linear-gradient(347deg, #29ac79, #113d83);
        background: linear-gradient(77deg, #29ac79, #113d83);
    }

    &__usp{
        position: relative;
        display: inline-block;
        width: 225px;
        height: 269px;
        padding: 80px 20px 20px;
        border-right: 1px solid #c3c3c3;
        overflow: hidden;
        font-size: 15px !important;
        
        &:after {
            position: absolute;
            top: 25px;
            left: 20px;
            font-size: 24px;
            line-height: 1;
            @include bovemij-icon(check);
        }
    }

    &__contact {
        position: absolute;
        top: 0;
        right: 0;
        width: 262px;
        height: 269px;
        padding: 20px 40px 20px 30px;
        background-color: #30ab7a;
        color: #FFF !important;     
        font-size: 16px;
        line-height: 24px;
        @include border-right-radius(10px);

        //TODO: Refactor to new solution
        &:after{
            border-image-source: -webkit-linear-gradient(321deg, #29ac79, #113d83);
            border-image-source: -moz-linear-gradient(321deg, #29ac79, #113d83);
            border-image-source: -o-linear-gradient(321deg, #29ac79, #113d83);
            border-image-source: linear-gradient(51deg, #29ac79, #113d83);
        }
    }

    &__contact-title {
        color: #ffffff;
        font-size: 16px;
        margin: 0 0 10px 0;
    }

    &__contact-body {
        margin: 0 0 20px 0;
    }

    &__contact-image{
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        width: 70px;
        height: 70px;
        overflow: hidden;
        @include border-radius(50%);
        @include transform(translate(50%, -50%));
        background: -webkit-linear-gradient(45deg, #259A7B, #124382);
        background: -moz-linear-gradient(45deg, #259A7B, #124382);
        background: -o-linear-gradient(45deg, #259A7B, #124382);
        background: linear-gradient(45deg, #259A7B, #124382);
        z-index: 9;
        
        @include media-breakpoint-up(lg) {
            display: block;
        }
    }
}

/* GX */

.c-usp-block__contact-image .contact-us-circle-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    padding: 1px;
}

.c-usp-block__contact-image .contact-us-circle-image img {
    width: 68px;
    height: 68px;
    border-radius: 50%;
}

.c-usp-block__contact,
.c-usp-block__contact p {
    color: #FFF;
    font-size: 15px;
    line-height: 1.5 !important
}


.c-job-offer {

    &__list{
        margin: 0;
        padding: 30px 15px;
        list-style: none;
    }

    &__item {
        position: relative;
        padding: 0 0 0 60px;
        margin: 15px 0 0 0;

        &:first-child {
            margin: 0;
        }

        &.is-hidden {
            display: none;
        }
    }

    &__link {
        color: #191919;
        text-decoration: none;
    }

    &__date {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 300;
        color: #0a519d;
    }

    &__title {

    }

    &__block {
        padding: 0;
    }

    &__more-button {
        position: relative;
        display: block;
        padding: 10px 15px;
        color: #123c81;
        border-top: 1px solid #d8d8d8;
        text-decoration: none;
        
        &:hover {
            text-decoration: none;
        }

        &:after {
            position: absolute;
            top: 50%;
            right: 15px;
            -moz-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);

            @include media-breakpoint-up(lg) {
                right: 25px;
            }
        }

        &.is-collapsed {
            &:after {
                @include bovemij-icon('chevron-circle-down');
                font-size: 18px;
            }
        }

        &.is-expanded {
            &:after {
                @include bovemij-icon('chevron-circle-up');
                font-size: 18px;
            }
        }

        &\@desktop {
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }
}

/* XC */

.c-job-offer .c-job-offer__block ul {
    @extend .c-job-offer__list;
}

.c-job-offer .c-job-offer__block ul li {
    @extend .c-job-offer__item;
}

.c-job-offer .c-job-offer__block ul li time {
    @extend .c-job-offer__date;
}

.c-job-offer .c-job-offer__block ul li a {
    @extend .c-job-offer__link;
}
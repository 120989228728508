.c-documents {
    position: relative;
    background-color: #30ab7a;
    margin: 0 -10px 0 -10px;
    padding: 30px 10px;
    @include border-right-radius(10px);

    @include media-breakpoint-up(sm) {
        margin: 0 -124px;
        padding: 30px 124px;
    }

    @include media-breakpoint-up(md) {
        margin: 0 -34px 0 -34px;
        padding: 35px 34px 65px 34px;
        @include border-radius(10px);
    }

    @include media-breakpoint-up(lg) {
        padding: 55px 130px 55px 50px;
        margin: 0 -130px 0 0;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 34px;
        height: 34px;
        background-image: url("../../img/bg_documents_br-corner-mobile.png");
        background-repeat: no-repeat;
        z-index: 1;

        @include media-breakpoint-up(md) {
            width: 72px;
            height: 72px;
            background-image: url("../../img/bg_documents_br-corner.png");
        }
    }

    &--clear {
        padding: 0;
        background-color: transparent;
        margin: 0;

        &:after {
            display: none;
        }
    }
}

.c-documents__item {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.c-documents__item-link {
    position: relative;
    display: block;
    width: auto;
    height: auto;
    border-radius: 10px;
    color: #000;
    font-size: 14px;
    line-height: 1.5;
    background-color: #ffffff;
    border: solid 1px #ececec;
    text-decoration: none;
    padding: 20px 20px 20px 65px;
    word-wrap: break-word;

    @include media-breakpoint-up(md) {
        padding-left: 75px;
    }

    &:before {
        position: absolute;
        top: 50%;
        left: 10px;
        content: "\f016";
        color:#de4e40;
        display: inline-block;
        font-family: 'FontAwesome';
        font-size: 20px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        @include media-breakpoint-up(md) {
            left: 17px;
            font-size: 23px;
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 36px;
        height: 100%;
        border-right: solid 1px #ececec;

        @include media-breakpoint-up(md) {
            width: 54px;
        }
    }
}

/* GX */
.c-documents .downloads a + a {
    margin-top: 20px;
}
.c-documents ul {
    position: relative;
    z-index: 2;
    @extend .o-list;
}

.c-documents ul li {
    @extend .c-documents__item;
}

.c-documents ul li a, .c-documents .downloads a {
    @extend .c-documents__item-link;
}

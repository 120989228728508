.u-fill-height {
    height: 100%;
    min-height: 100%;
}

.u-full-viewport-height {
    height: 100vh;
}

.u-full-width-image {
    display: block;
    width: 100%;
    height: auto;
}

.u-responsive-image {
    &--height-364px\@tablet-and-down {
        @include media-breakpoint-down(md) {
            width: auto;
            height: 364px;
            display: block;
        }
    }
}

/* GX */

.u-full-width-image img {
    @extend .u-full-width-image;
}

.u-responsive-image--height-364px\@tablet-and-down img {
    @extend .u-responsive-image--height-364px\@tablet-and-down;
}
.c-dropdown {
    
    &__checkbox {
        display: none;
    }

    &__checkbox:not(:checked) ~ &__label:before {
        @include bovemij-icon(chevron-circle-down);
    }

    &__checkbox:checked ~ &__label:before {
        @include bovemij-icon(chevron-circle-up);
    }

    &__label {
        color: transparent;
        text-indent: -99999px;
        
        &:before {
            color: #FFF !important;
            text-indent: 0;
        }
    }

    &__checkbox:not(:checked) ~ &__content {
        display: none;
    }

    &__checkbox:checked ~ &__content {
        display: block;
    }

    &__content {
        padding: 0 15px;
        background-color: #FFF;
        @include border-bottom-radius(10px);

        &--align-right, &--align-left {
            position: absolute;
        }

        &--align-right {
            right: 0;
        }

        &--align-left {
            left: 0;
        }
    }

    &__list {

    }

    &__item {
        position: relative;
        border-bottom: 1px solid #ececec;

        &:last-child {
            border-bottom: 0 none;
        }
    }

    &__link {
        display: block;
        font-size: 14px;
        color: #191919;
        padding: 10px 0 10px 25px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &--icon-arrow {
            &:before {
                position: absolute;
                top: 50%;
                left: 0;
                content: "\f178";
                color: #30ab7a;
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
    }
}
.c-faq {

    &__title {
        font-weight: 500;
        line-height: 1.5;
        font-size: 16px;
        color: #123c81;
        margin-bottom: 26px;
    } 

    &__topic {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }  

    &__question { 
        position: relative;
        display: block;
        width: 100%;
        padding: 15px 10px 15px 45px;
        color: #000000;
        background-color: #ffffff;
        border: 1px solid #ececec;
        overflow: hidden;
        cursor: pointer;
        @include border-radius(10px);
        border-bottom: 2px solid #d2d2d5;
        
        @include media-breakpoint-up(sm) {
            height: 54px;
            min-height: 0;
            padding: 0 20px 0 75px;
            white-space: nowrap;
            line-height: 54px;
            text-overflow: ellipsis;
        }
        
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -1px;
            width: 36px;
            height: 100%;   
            border-right: 1px solid #ececec;  

            @include media-breakpoint-up(sm) {
                width: 54px;
            }
        }

        &:after {
            position: absolute;
            top: 50%;
            left: 9px;
            display: inline-block;
            @include bovemij-icon(plus);
            color: #0a519d;
            font-size: 18px;
            @include transform(translateY(-50%));

            @include media-breakpoint-up(sm) {
                left: 14px;
                font-size: 24px;
            }
        }

        &.is-open{
            color: #FFFFFF;
            background-color: #30ab7a;
            border: 1px solid #128964;
            @include border-bottom-radius(0px);

            &:before{
                border-right: 1px solid #128964;
            }

            &:after {
                color: #FFF;
            }
        }
    }

    &__answer { 
        height: 0;
        background-color: #ffffff;
        overflow: hidden;
        padding: 0 30px;
        @include border-bottom-radius(10px);
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;  
        
        &.show {
            height: auto;
            padding: 20px 30px;
            border-bottom: 2px solid #d2d2d5;
        }
    }
    
    &__read-more-link{
        color: #000000;
        text-decoration: none;

        &:hover{
            text-decoration: none;
            
            > span{
                color: #000000;
                text-decoration: underline;
            }
        }

        > i.fa {
            color: #30ab7a;
        }
    }
}

.faq-link {
    margin-top: 35px;
}


/* GX */
.faq-default .wrapper .item {
    @extend .c-faq__topic;

    &:last-child {
        margin-bottom: 0;
    }
}

.faq-default .wrapper .item .question{
    @extend .c-faq__question;
}

.faq-default .wrapper .item .answer{
    @extend .c-faq__answer;
}

.faq-default .wrapper .item .answer a {
    @extend .c-faq__read-more-link;

    &:before {
        content: "\f178";
        color: #30ab7a;
        margin-right: 5px;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.faq-default .wrapper .clearer {
    margin-top: -20px;
}

.last-row .faq-link {
    display: block;
    margin-bottom: 75px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 90px;
    }
}
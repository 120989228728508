.c-help-text {
    margin: 35px 0 15px 0;
    font-size: 14px;
    text-align: center;
    color: $text-blue-light;
    @include transform(rotate(5deg));

    &:before {
        position: absolute;
        font-size: 33px;
        @include bovemij-icon(arrow-handdrawn);
        color: #0a519d;
        left: -40px;
        top: -5px;
        @include transform(rotate(40deg) scaleX(-1));
    }

    p {
        color: $text-blue-light;
    }
}

.c-help-text--bottom-to-bottom::before {
    top: auto;
    right: 40px;
    bottom: -40px;
    left: auto;
    @include transform(rotate(-80deg) scaleX(-1));
}

.c-help-text--bottom-to-left::before {
    top: auto;
    right: auto;
    bottom: -40px;
    left: 10px;
    @include transform(rotate(-10deg) scaleX(-1));
}

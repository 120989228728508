/* General font settings */

$bovemij-icon-font-name: "bovemij-icons" !default;
$bovemij-icon-font-path: "../../webfonts/icons" !default;

@font-face {
    font-family: $bovemij-icon-font-name;
    src:  url('#{$bovemij-icon-font-path}/bovemij-icons.eot?s4u3fz');
    src:  url('#{$bovemij-icon-font-path}/bovemij-icons.eot?s4u3fz#iefix') format('embedded-opentype'),
            url('#{$bovemij-icon-font-path}/bovemij-icons.woff2?s4u3fz') format('woff2'),
            url('#{$bovemij-icon-font-path}/bovemij-icons.ttf?s4u3fz') format('truetype'),
            url('#{$bovemij-icon-font-path}/bovemij-icons.woff?s4u3fz') format('woff'),
            url('#{$bovemij-icon-font-path}/bovemij-icons.svg?s4u3fz#bovemij-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

%bovemij-icon {
    &:before, &:after{
        font-family: $bovemij-icon-font-name !important;
        line-height: 1 !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        vertical-align: middle;
    }
}

@mixin bovemij-icon($icon) {
    @extend %bovemij-icon;
    @extend .icon-#{$icon}:before !optional;
    color: $icon-base;
}

[class^="icon-"], [class*=" icon-"] {
    @extend %bovemij-icon;
}

/* Icon variables */

$icon-arrow-circle-up: "\e901";
$icon-arrow-circle-down: "\e902";
$icon-arrow-circle-left: "\e903";
$icon-arrow-circle-right: "\e904";
$icon-chevron-circle-up: "\e905";
$icon-chevron-circle-down: "\e906";
$icon-chevron-circle-left: "\e907";
$icon-chevron-circle-right: "\e908";
$icon-arrow-previous: "\e909";
$icon-arrow-next: "\e90a";
$icon-menu: "\e90b";
$icon-close: "\e90c";
$icon-user: "\e90d";
$icon-search: "\e90e";
$icon-phone: "\e90f";
$icon-risicomanagement: "\e910";
$icon-nav-nieuweactiviteiten: "\e911";
$icon-nav-klantenbinden: "\e912";
$icon-verzekeren: "\e913";
$icon-financieringen: "\e914";
$icon-rechtsbijstand: "\e915";
$icon-datainternet: "\e916";
$icon-arrow-handdrawn: "\e917";
$icon-check: "\e918";
$icon-plus: "\e919";
$icon-cross: "\e91a";
$icon-calendar: "\e91b";
$icon-clock: "\e91c";
$icon-filter: "\e91d";
$icon-download: "\e91e";
$icon-document: "\e91f";
$icon-clipboard: "\e920";
$icon-maps: "\e921";
$icon-key: "\e922";
$icon-password-hidden: "\e923";
$icon-password-shown: "\e924";
$icon-dna: "\e925";
$icon-schade: "\e926";
$icon-preventietips: "\e927";
$icon-leasevloot: "\e928";
$icon-particulieren: "\e929";
$icon-garantie: "\e92a";
$icon-case: "\e92b";
$icon-aansprakelijkheid: "\e92c";
$icon-arbeidsongeschiktheid: "\e92d";
$icon-gebouwbrand: "\e92e";
$icon-gebouwschade: "\e92f";
$icon-gebouwterrein: "\e930";
$icon-geldtransport: "\e931";
$icon-clientobject: "\e932";
$icon-handelsvoorraad-allround: "\e933";
$icon-handelsvoorraad-brand: "\e934";
$icon-handelsvoorraad-casco: "\e935";
$icon-kenteken: "\e936";
$icon-milieu: "\e937";
$icon-ongevallen: "\e938";
$icon-ziektekosten: "\e939";
$icon-WIA: "\e93a";
$icon-tankstation: "\e93b";
$icon-werkmaterieel: "\e93c";
$icon-edit: "\e93d";
$icon-oldtimer: "\e93e";
$icon-motorrijtuigen: "\e93f";
$icon-vrachtwagen: "\e940";
$icon-personenwagen: "\e941";
$icon-caravan: "\e942";
$icon-carosserie: "\e943";
$icon-bestelbus: "\e944";
$icon-aanhangwagen: "\e945";
$icon-personenbus: "\e946";
$icon-revisie: "\e900";
$icon-rijschool: "\e947";
$icon-schadeherstel: "\e948";
$icon-e-bike: "\e949";
$icon-fiets: "\e94a";
$icon-was-en-poets: "\e94b";
$icon-bromfiets: "\e94c";
$icon-info-outline: "\e94d";
$icon-info-filled: "\e94e";
$icon-chat: "\e94f";
$icon-email: "\e950";
$icon-linkedin: "\e951";
$icon-facebook: "\e952";
$icon-twitter: "\e953";
$icon-whatsapp: "\e954";
$icon-device-google: "\e955";
$icon-device-windows: "\e956";
$icon-device-apple: "\e957";
$icon-wave: "\e958";
$icon-motor: "\e959";

/* Icon styling */

.icon-arrow-circle-up {
    &:before {
        content: $icon-arrow-circle-up;
    }
}

.icon-arrow-circle-down {
    &:before {
        content: $icon-arrow-circle-down;
    }
}

.icon-arrow-circle-left {
    &:before {
        content: $icon-arrow-circle-left;
    }
}

.icon-arrow-circle-right {
    &:before {
        content: $icon-arrow-circle-right;
    }
}

.icon-chevron-circle-up {
    &:before {
        content: $icon-chevron-circle-up;
    }
}

.icon-chevron-circle-down {
    &:before {
        content: $icon-chevron-circle-down;
    }
}

.icon-chevron-circle-left {
    &:before {
        content: $icon-chevron-circle-left;
    }
}

.icon-chevron-circle-right {
    &:before {
        content: $icon-chevron-circle-right;
    }
}

.icon-arrow-previous {
    &:before {
        content: $icon-arrow-previous;
    }
}

.icon-arrow-next {
    &:before {
        content: $icon-arrow-next;
    }
}

.icon-menu {
    &:before {
        content: $icon-menu;
    }
}

.icon-close {
    &:before {
        content: $icon-close;
    }
}

.icon-user {
    &:before {
        content: $icon-user;
    }
}

.icon-search {
    &:before {
        content: $icon-search;
    }
}

.icon-phone {
    &:before {
        content: $icon-phone;
    }
}

.icon-risicomanagement {
    &:before {
        content: $icon-risicomanagement;
    }
}

.icon-nav-nieuweactiviteiten {
    &:before {
        content: $icon-nav-nieuweactiviteiten;
    }
}

.icon-nav-klantenbinden {
    &:before {
        content: $icon-nav-klantenbinden;
    }
}

.icon-verzekeren {
    &:before {
        content: $icon-verzekeren;
    }
}

.icon-financieringen {
    &:before {
        content: $icon-financieringen;
    }
}

.icon-rechtsbijstand {
    &:before {
        content: $icon-rechtsbijstand;
    }
}

.icon-datainternet {
    &:before {
        content: $icon-datainternet;
    }
}

.icon-arrow-handdrawn {
    &:before {
        content: $icon-arrow-handdrawn;
    }
}

.icon-check {
    &:before {
        content: $icon-check;
    }
}

.icon-plus {
    &:before {
        content: $icon-plus;
    }
}

.icon-cross {
    &:before {
        content: $icon-cross;
    }
}

.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}

.icon-clock {
    &:before {
        content: $icon-clock;
    }
}

.icon-filter {
    &:before {
        content: $icon-filter;
    }
}

.icon-download {
    &:before {
        content: $icon-download;
    }
}

.icon-document {
    &:before {
        content: $icon-document;
    }
}

.icon-clipboard {
    &:before {
        content: $icon-clipboard;
    }
}

.icon-maps {
    &:before {
        content: $icon-maps;
    }
}

.icon-key {
    &:before {
        content: $icon-key;
    }
}

.icon-password-hidden {
    &:before {
        content: $icon-password-hidden;
    }
}

.icon-password-shown {
    &:before {
        content: $icon-password-shown;
    }
}

.icon-dna {
    &:before {
        content: $icon-dna;
    }
}

.icon-schade {
    &:before {
        content: $icon-schade;
    }
}

.icon-preventietips {
    &:before {
        content: $icon-preventietips;
    }
}

.icon-leasevloot {
    &:before {
        content: $icon-leasevloot;
    }
}

.icon-particulieren {
    &:before {
        content: $icon-particulieren;
    }
}

.icon-garantie {
    &:before {
        content: $icon-garantie;
    }
}

.icon-case {
    &:before {
        content: $icon-case;
    }
}

.icon-aansprakelijkheid {
    &:before {
        content: $icon-aansprakelijkheid;
    }
}

.icon-arbeidsongeschiktheid {
    &:before {
        content: $icon-arbeidsongeschiktheid;
    }
}

.icon-gebouwbrand {
    &:before {
        content: $icon-gebouwbrand;
    }
}

.icon-gebouwschade {
    &:before {
        content: $icon-gebouwschade;
    }
}

.icon-gebouwterrein {
    &:before {
        content: $icon-gebouwterrein;
    }
}

.icon-geldtransport {
    &:before {
        content: $icon-geldtransport;
    }
}

.icon-clientobject {
    &:before {
        content: $icon-clientobject;
    }
}

.icon-handelsvoorraad-allround {
    &:before {
        content: $icon-handelsvoorraad-allround;
    }
}

.icon-handelsvoorraad-brand {
    &:before {
        content: $icon-handelsvoorraad-brand;
    }
}

.icon-handelsvoorraad-casco {
    &:before {
        content: $icon-handelsvoorraad-casco;
    }
}

.icon-kenteken {
    &:before {
        content: $icon-kenteken;
    }
}

.icon-milieu {
    &:before {
        content: $icon-milieu;
    }
}

.icon-ongevallen {
    &:before {
        content: $icon-ongevallen;
    }
}

.icon-ziektekosten {
    &:before {
        content: $icon-ziektekosten;
    }
}

.icon-WIA {
    &:before {
        content: $icon-WIA;
    }
}

.icon-tankstation {
    &:before {
        content: $icon-tankstation;
    }
}

.icon-werkmaterieel {
    &:before {
        content: $icon-werkmaterieel;
    }
}

.icon-edit {
    &:before {
        content: $icon-edit;
    }
}

.icon-oldtimer {
    &:before {
        content: $icon-oldtimer;
    }
}

.icon-motor {
    &:before {
        content: $icon-motor;
    }
}

.icon-motorrijtuigen {
    &:before {
        content: $icon-motorrijtuigen;
    }
}

.icon-vrachtwagen {
    &:before {
        content: $icon-vrachtwagen;
    }
}

.icon-personenwagen {
    &:before {
        content: $icon-personenwagen;
    }
}

.icon-caravan {
    &:before {
        content: $icon-caravan;
    }
}

.icon-carosserie {
    &:before {
        content: $icon-carosserie;
    }
}

.icon-bestelbus {
    &:before {
        content: $icon-bestelbus;
    }
}

.icon-aanhangwagen {
    &:before {
        content: $icon-aanhangwagen;
    }
}

.icon-personenbus {
    &:before {
        content: $icon-personenbus;
    }
}

.icon-revisie {
    &:before {
        content: $icon-revisie;
    }
}

.icon-rijschool {
    &:before {
        content: $icon-rijschool;
    }
}

.icon-schadeherstel {
    &:before {
        content: $icon-schadeherstel;
    }
}

.icon-e-bike {
    &:before {
        content: $icon-e-bike;
    }
}

.icon-fiets {
    &:before {
        content: $icon-fiets;
    }
}

.icon-was-en-poets {
    &:before {
        content: $icon-was-en-poets;
    }
}

.icon-bromfiets {
    &:before {
        content: $icon-bromfiets;
    }
}

.icon-info-outline {
    &:before {
        content: $icon-info-outline;
    }
}

.icon-info-filled {
    &:before {
        content: $icon-info-filled;
    }
}

.icon-chat {
    &:before {
        content: $icon-chat;
    }
}

.icon-email {
    &:before {
        content: $icon-email;
    }
}

.icon-linkedin {
    &:before {
        content: $icon-linkedin;
    }
}

.icon-facebook {
    &:before {
        content: $icon-facebook;
    }
}

.icon-twitter {
    &:before {
        content: $icon-twitter;
    }
}

.icon-whatsapp {
    &:before {
        content: $icon-whatsapp;
    }
}

.icon-device-google {
    &:before {
        content: $icon-device-google;
    }
}

.icon-device-windows {
    &:before {
        content: $icon-device-windows;
    }
}

.icon-device-apple {
    &:before {
        content: $icon-device-apple;
    }
}

.icon-wave {
    &:before {
        content: $icon-wave;
    }
}

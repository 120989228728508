.formsteps {
    margin-bottom: 50px;
    
    ol {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        padding-left: 0;
        list-style: none;
        counter-reset: formsteps;

        .step {
            -webkit-flex: 1 0;
            -ms-flex: 1 0;
            flex: 1 0;

            &:before {
                counter-increment: formsteps;
                content: counter(formsteps) ". ";
            }

            &.active {
                color: $color-green;

                &:before {
                    color: $color-green;
                }

                span {
                    border-bottom: 2px solid;
                }
            }
        }
    }
}

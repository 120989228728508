@mixin flex($fg: 1, $fs: null, $fb: null) {
	$fg-boxflex: $fg;

	@if type-of($fg) == 'list' {
		$fg-boxflex: nth($fg, 1);
	}

	-webkit-box-flex: $fg-boxflex;
	-webkit-flex: $fg $fs $fb;
	-moz-box-flex: $fg-boxflex;
	-moz-flex: $fg $fs $fb;
	-ms-flex: $fg $fs $fb;
	flex: $fg $fs $fb;
}

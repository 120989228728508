.c-page-nav {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #dadada;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    &__prev,
    &__next {
        display: inline-block;
        float: left;
        position: relative;

        &:before {
            width: 40px;
            height: 40px;
            text-align: center;
            background-color: #30ab7a;
            position: absolute;
            top: 25px;
            transform: translateY(-50%);
            border-radius: 10px;
            color: #fff !important;
            line-height: 40px !important;
        }

        label {
            color: #0a5f55;
            font-size: 14px;
        }

        .title {
            font-size: 16px;
            color: #123c81;
        }
    }

    &__next {
        text-align: right;
        width: 100%;
        padding: 0 60px; 

        &:before {
            @include bovemij-icon(arrow-next);
            right: 0;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
            padding: 0 60px 0 30px;
        }
    }

    &__prev {
        width: 60px;
        padding: 0 60px;

        .title, label {
            display: none;
        }

        &:before {
            @include bovemij-icon(arrow-previous);
            left: 0;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
            padding: 0 30px 0 60px;

            .title, label {
                display: block;
            }
        }
    }
}

.c-image-carousel {
    

    &__list {
        @include make-row();
    }

    &__item {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(6);
        }

        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
    }

    &__image {
        display: block;
        width: 100%;
    }

    &__control {
        width: 8.333%;
        
        &--previous {

        }

        &--next {

        }
    }
}

/* XC */

.c-image-carousel .wrapper {
    @extend .c-image-carousel__list;
}

.c-image-carousel {
    
}

.c-image-carousel .wrapper .item {
    @extend .c-image-carousel__item;
}

.c-image-carousel .wrapper .item img {
    @extend .c-image-carousel__image;
}
.c-action {
    position: relative;
    width: 100%;
    padding: 20px 30px 20px 45px;

    @include media-breakpoint-up(lg) {
        width: auto;
        padding: 10px 30px 0 25px;
    }

    &:before {
        position: absolute;
        top: 2px;
        left: 15px;
        font-size: 40px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.2);

        @include media-breakpoint-up(lg) {
            top: 10px;
            left: 0;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-image: -webkit-linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.38) 49%, rgba(255, 255, 255, 0));
        background-image: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.38) 49%, rgba(255, 255, 255, 0));
        background-image: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.38) 49%, rgba(255, 255, 255, 0));
        background-image: -o-linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.38) 49%, rgba(255, 255, 255, 0));
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.38) 49%, rgba(255, 255, 255, 0));

        @include media-breakpoint-up(lg) {
            top: 0;
            right: 0;
            left: auto;
            width: 1px;
            height: 100%;
        }
    }

    &--first:before {
        content: "1";
    }

    &--second:before {
        content: "2";
    }

    &--third:before {
        content: "3";
    }

    &__text {
        position: relative;
        display: inline-block;
        color: #FFF;
        font-size: 16px;
        font-weight: 300;
        z-index: 99;

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }

        @include media-breakpoint-up(lg) {
            display: block;
            padding: 0 0 0 30px;
        }

        &--bold {
            font-weight: 500;

            @include media-breakpoint-up(lg) {
                padding: 0 0 0 30px;
            }
        }
    }

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        box-sizing: content-box;
        cursor: pointer;

        .editableContainer {
            height: 100%;
            width: 100%;

            a {
                display: block;
                height: 100%;
                width: 100%;
                text-indent: -9999px;
            }
        }

        @include media-breakpoint-up(lg) {
            font-size: 28px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 26px;
            height: 26px;
            @include border-radius(50%);
            background-color: #30ab7a;
            z-index: 1;
            -moz-transform: translate(50%, -50%);
            -webkit-transform: translate(50%, -50%);
            -o-transform: translate(50%, -50%);
            -ms-transform: translate(50%, -50%);
            transform: translate(50%, -50%);

            @include media-breakpoint-up(lg) {
                top: auto;
                right: auto;
                bottom: 0;
                left: 50%;
                -moz-transform: translate(-50%, 50%);
                -webkit-transform: translate(-50%, 50%);
                -o-transform: translate(-50%, 50%);
                -ms-transform: translate(-50%, 50%);
                transform: translate(-50%, 50%);
            }
        }

        &:after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 28px;
            height: 28px;
            @include bovemij-icon(arrow-circle-down);
            color: #FFF;
            font-size: 28px;
            z-index: 2;
            -moz-transform: translate(50%, -50%);
            -webkit-transform: translate(50%, -50%);
            -o-transform: translate(50%, -50%);
            -ms-transform: translate(50%, -50%);
            transform: translate(50%, -50%);

            @include media-breakpoint-up(lg) {
                top: auto;
                right: auto;
                bottom: 0;
                left: 50%;
                -moz-transform: translate(-50%, 50%);
                -webkit-transform: translate(-50%, 50%);
                -o-transform: translate(-50%, 50%);
                -ms-transform: translate(-50%, 50%);
                transform: translate(-50%, 50%);
            }
        }
    }
}

.c-search-input {
    &__body {
        position: relative;
        width: 100%;
        max-width: 300px;

        &:after {
            position: absolute;
            top: 50%;
            right: 15px;
            font-size: 18px;
            @include bovemij-icon(search);
            color: $text-blue;
            z-index: 9;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    &__input {
        width: 100%;
        height: 40px;
        padding: 10px 45px 10px 15px;
        font-size: 14px;
        background: #FFF;
        border: 1px solid #d2d2d5;
        @include border-radius(10px);

        &:focus {
            outline: 0 none;
            border-color: $color-green;
        }

        &::-webkit-input-placeholder {
            font-style: italic;
        }

        &::-moz-placeholder {
            font-style: italic;
        }

        &:-ms-input-placeholder {
            font-style: italic;
        }

        &:-moz-placeholder {
            font-style: italic;
        }
    }

    &__button {
        position: absolute;
        top: 0;
        right: 0;
        width: 45px;
        height: 40px;
        margin: 0;
        padding: 0;
        background-color: transparent;
        @include border-right-radius(10px);
        border: 0 none;
        z-index: 99;
        cursor: pointer;

        &:focus {
            outline: 0 none;
        }
    }
}


.c-search-input {

    .formtextbox input {
        @extend .c-search-input__input;
    }

    .formbutton {
        @extend .c-search-input__button;
        opacity: 0;

        input {
            height: 40px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .fieldgrp {
        @extend .c-search-input__body;
    }

    legend {
        span {
            color: $text-blue;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.ui-autocomplete {
    margin-top: 50px;
    max-width: 300px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #30ab7a;
    border-radius: 10px;
    list-style: none;
    position: absolute;
    z-index: 99;

    -webkit-box-shadow: 0px 10px 25px -5px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 10px 25px -5px rgba(0,0,0,0.4);
    box-shadow: 0px 10px 25px -5px rgba(0,0,0,0.4);

    &:after {
        content: "";
        width: 20px;
        height: 20px;
        border-top: 1px solid #30ab7a;
        border-right: 1px solid #30ab7a;
        background-color: #fff;
        display: block;
        position: absolute;
        top: -11px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    li {
        display: block;

        &:hover {
            cursor: pointer;
        }

        &::before {
            color: $color-green;
            text-decoration: none;
            margin-right: 10px;
            content: "\f178";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 14px;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}

._hide-search-input-title legend {
    display: none;
}
.c-story-of-block {
    position: relative;

    &__title {
        margin: 0;
        font-family: "GT Walsheim Pro";
        font-size: 40px;
        font-weight: 500;
        line-height: 1;
        color: $title-color;
        text-align: left;
    }

    &__quote {
        margin: -20px 10px 0 10px;
        border-bottom: 2px solid #dadada;
        padding: 15px;
        color: #123c81;
        font-weight: 500;

        @include media-breakpoint-up(sm) {
            position: absolute;
            top: 84px;
            right: 0;
            left: auto;
            width: 200px;
            height: auto;
            max-height: 159px;
            margin: 0;
        }

        @include media-breakpoint-up(lg) {
            border: 0 none;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        }
    }

    &__link {
        padding: 12px 56px 12px 12px;
        overflow: hidden;
    }

    &__background {
        width: 100%;
        height: auto;
        overflow: hidden;
        @include border-radius(10px);

        @include media-breakpoint-up(sm) {
            width: -moz-calc(100% - 20px);
            width: -webkit-calc(100% - 20px);
            width: calc(100% - 20px);
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: auto;
    }

    &__meta {
        position: relative;
        height: 100px;
        width: 100%;
        padding: 24px 24px 42px 24px;
        color: #FFF;
        margin-top: -100px;
        background-color: rgba(26, 26, 26, 0.35);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @include media-breakpoint-up(sm) {
            position: absolute;
            bottom: 0;
            left: 0;
            height: auto;
            width: -moz-calc(100% - 20px);
            width: -webkit-calc(100% - 20px);
            width: calc(100% - 20px);
            padding: 24px;
            margin-top: 0;
        }
    }

    &__person,
    &__department {
        display: block;
        line-height: 1.5;
    }

    &__person {
        font-size: 16px;
    }

    &__department {
        font-size: 12px;
    }
}

.c-block-story__readmore a{
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #000;
    text-decoration: none;
    padding: 0 0 0 20px;
    margin: 10px 0 0 0;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &:before {
        position: absolute;
        top: 3px;
        left: 0;
        color: #30ab7a;
        content: "\f178";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.c-story-of-block.c-story-of-block--large {
    max-width: 780px;

    .c-story-of-block__quote {
        right: -8.333%;
        width: 327px;
        min-height: 156px;
        max-height: 288px;
        font-size: 22px;
        font-weight: 500;
        padding: 24px;
    }
}

/* GX */
.c-story-of-block:before{
    content: "Verhaal van";
    @extend .c-story-of-block__title;
}

.c-story-of-block__background img {
    @extend .c-story-of-block__image;
}



.c-video {
    &__body {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        margin: 0 0 20px 0;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__title {
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        color: #0a5f55;
    }
}

/* XC */

.c-video .c-video__body .oembed-youtube{
    position: absolute;
    width: 100% !important;
    padding-bottom: 56.25%;
    top: 0;
    left: 0;
    height: 0;
}

.c-video .c-video__body .oembed-youtube .content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.c-video .c-video__body iframe {
    width: 100%;
    height: 100%;
}

.c-video .c-video__body .rte_container .overlay {
    z-index: 9999;
}
.c-news-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    &__background {
        width: 100%;
        height: auto;
        overflow: hidden;
        @include border-radius(10px);

        &:empty {
            padding-top: 28px;
        }
    }

    &__background-image {
        display: block;
        width: 100%;
        height: auto;
    }

    &__summary {
        position: relative;
        flex: 1 1 auto;
        margin: -28px 10px 0;
        padding: 20px;
        @include clearfix;

        @include media-breakpoint-up(sm) {
            top: 0;
            margin: -28px 10px 0;
            padding: 20px 20px 80px 20px;
        }
    }

    &__title {
        margin: 0 0 20px 0;
        font-family: "GT Walsheim Pro";
        font-weight: 500;
        line-height: 1.5;
        text-align: left;
        color: #191919;
    }

    &__read-more {
        display: block;
        font-family: "GT Walsheim Pro";
        font-size: 16px;
        line-height: 1.5;
        text-align: left;
        color: #191919;
        margin: 0 0 20px 0;
        text-decoration: none;

        &:before {
            color: #30ab7a;
            margin-right: 10px;
            content: "\f178";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    &__meta {
        padding-top: 20px;
        border-top: 1px solid #ececec;
        @include clearfix;

        @include media-breakpoint-up(sm) {
            position: absolute;
            bottom: 20px;
            width: -webkit-calc(100% - 40px);
            width: expression(100% - 40px);
            width: -moz-calc(100% - 40px);
            width: -o-calc(100% - 40px);
            width: calc(100% - 40px);
        }
    }

    &__name {
        position: relative;
        float: left;
        padding: 16px 0 0 0;
        color: #0a519d;
        font-size: 14px;

        @include media-breakpoint-only(sm) {
            width: 80px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:before {
            content: none;

            @include media-breakpoint-up(sm) {
                position: absolute;
                left: 0;
                top: 0;
                content: "Door";
                font-size: 12px;
                color: #191919;
            }
        }
    }

    &__date {

        float: right;
        font-size: 14px;
        width: auto;
        color: #191919;
        padding: 16px 0 0 0;

        &:before {
            position: absolute;
            right: 0;
            top: 0;
            content: "Datum";
            font-size: 12px;
            color: #191919;
        }

        &--icon {
            position: relative;
            padding-left: 22px;

            &:after {
                position: absolute;
                top: 18px;
                left: 0;
                @include bovemij-icon(calendar);
                color: #0a519d;
                font-size: 15px;
                margin: 0 5px 0 0;
            }
        }
    }

    &__thumbnail {
        float: left;
        position: relative;
        width: 38px;
        height: 38px;
        margin: 0 10px 0 0;
        overflow: hidden;
        background: linear-gradient(52deg, #29ac79, #113d83);
        @include border-radius(50%);

        &:after{
            content: "";
            position: absolute;
            top: 1px;
            left: 1px;
            width: 36px;
            height: 36px;
            background-color: #FFF;
            @include border-radius(50%);
            z-index: 1;
        }
    }

    &__thumbnail-image {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 36px;
        height: 36px;
        background-color: #FFF;
        z-index: 2;
        border-radius: 50%;
    }
}

.c-news-block__item-summary--list-item {
    padding: 0;
    margin: 0;
}

.c-news-block__item-summary--list-item .c-news-item__date{
    float: left;
    width: 50px;
    font-size: 16px;
    color: #0a519d;
    padding: 0;
    font-weight: 300;

    &:before {
        display: none;
    }
}

.c-news-block__item-summary--list-item .c-news-item__title {
    margin: 0;
    padding: 0 0 0 50px;

    .title {
        margin: 0;
        padding: 0;

        a {
            color: #191919;
            text-decoration: none;
            font-weight: normal;
        }
    }
}

/* GX */

.c-news-item__background .thumb:empty {
   @extend .c-news-item__background:empty;
}

.c-news-item__background img {
    @extend .c-news-item__background-image;
}

.c-news-item__thumbnail img {
    @extend .c-news-item__thumbnail-image;
}

.c-step {

    &__title {
        padding-top: 115px;

        p {
            margin-bottom: 0;
        }

        &:before {
            font-size: 200px;
            display: inline-block;
            position: absolute;
            color: rgba(48, 171, 122, 0.2);
            top: -60px;
            left: 0px;
            height: 0;
        }

        &--first:before {
            content: "1";
        }

        &--second:before {
            content: "2";
        }

        &--third:before {
            content: "3";
        }

        &--large {
            color: rgba(48,171,122,0.2);
            font-size: 30px;
            margin: 0 0 30px;
            padding-top: 0;
            font-weight: 500;
            min-width: 300px;

            @include media-breakpoint-up(md) {
                font-size: 50px;
                white-space: nowrap;
                float: right;
                margin: 10px 0 20px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 60px;
            }
        }
    }

    &__tip {
        margin: -25% 10px 0 10px;
        margin-left: 10px;
        margin-right: 10px;

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 50%;
            left: 0;
            width: calc(80% - 20px);
            margin: auto;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}

/* GX */

.c-step__title--large * {
    color: inherit;
}

form:not(.sitewidesearch) {

    fieldset.form {

        legend {
            display: none;
        }

        table {
            display: table;
            table-layout: fixed;
            width: 100%;
        }

        .fieldgrp {
            margin: 0 0 35px 0;

            .fieldgrp {
                margin: 0;
            }

            &.error {
                .wm-field-input {
                    position: relative;

                    &:after {
                        position: absolute;
                        top: 17px;
                        right: -30px;
                        @include bovemij-icon(cross);
                        color: #DE4E40;
                        font-size: 20px;
                    }
                }

                .formradio .wm-field-input:after,
                .formcheckbox .wm-field-input:after,
                .formtextarea .wm-field-input:after{
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);
                }

                .error-message {
                    display: none !important;
                    visibility: hidden;
                }
            }

            .formtextbox,
            .formdate-default {
                width: 85%;
                max-width: 380px;

                .wm-field-label {
                    position: relative;

                    label {
                        position: absolute;
                        top: 0;
                        left: 24px;
                        padding: 0 10px;
                        margin: 0;
                        color: #2e6caf;
                        font-size: 12px;
                        background-color: #FFF;
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        transform: translateY(-50%);
                        z-index: 99;
                    }
                }

                .wm-field-input {
                    height: auto;
                    min-height: 54px;
                    border: 1px solid #30ab7a;
                    @include border-radius(10px);

                    input {
                        height: 52px;
                        padding: 0 24px;
                        width: 100%;
                        color: #191919;
                        font-size: 16px;
                        border: 0 none;
                        @include border-radius(10px);

                        &:focus {
                            outline: 0 none;
                        }
                    }

                    input[type=file] {
                        position: absolute;
                        top: 50%;
                    }
                }
            }

            .formfileupload {
                width: 85%;
                max-width: 380px;

                .wm-field-label {
                    position: relative;

                    label {
                        position: absolute;
                        top: 0;
                        left: 24px;
                        padding: 0 10px;
                        margin: 0;
                        color: #2e6caf;
                        font-size: 12px;
                        background-color: #FFF;
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        transform: translateY(-50%);
                        z-index: 99;
                    }
                }

                .wm-field-input {
                    height: auto;
                    min-height: 54px;
                    border: 1px solid #30ab7a;
                    @include border-radius(10px);

                    .field {
                        overflow: hidden;
                    }

                    input {
                        height: 52px;
                        padding: 10px 24px;
                        width: 100%;
                        color: #191919;
                        font-size: 16px;
                        border: 0 none;
                        background: transparent;

                        &:focus {
                            outline: 0 none;
                        }
                    }
                }
            }

            .formtextarea {
                padding: 0 30px 0 0;

                .wm-field-label {
                    position: relative;

                    label {
                        position: absolute;
                        top: 0;
                        left: 24px;
                        padding: 0 10px;
                        margin: 0;
                        color: #2e6caf;
                        font-size: 12px;
                        background-color: #FFF;
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        transform: translateY(-50%);
                        z-index: 99;
                    }
                }

                .wm-field-input {
                    height: auto;
                    min-height: 54px;
                    border: 1px solid #30ab7a;
                    @include border-radius(10px);

                    textarea {
                        width: 100%;
                        color: #191919;
                        font-size: 16px;
                        border: 0 none;
                        padding: 16px 24px;
                        resize: none;
                        @include border-radius(10px);

                        &:focus {
                            outline: 0 none;
                        }
                    }
                }

            }

            .formradio{
                padding: 0 30px 0 0;

                .wm-field-label {
                    position: relative;

                    label {
                        position: absolute;
                        top: 0;
                        left: 24px;
                        padding: 0 10px;
                        margin: 0;
                        color: #2e6caf;
                        font-size: 12px;
                        background-color: #FFF;
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        transform: translateY(-50%);
                        z-index: 99;
                    }
                }

                .wm-field-input {
                    height: auto;
                    padding: 10px;
                    min-height: 54px;
                    border: 1px solid #30ab7a;
                    @include border-radius(10px);

                    .field {
                        @extend .c-radio;

                        > input {
                            @extend .c-radio__input;
                        }

                        > label {
                            @extend .c-radio__label;
                            color: #0a519d !important;
                            font-size: 16px !important;
                            width: auto !important;
                        }
                    }
                }
            }

            .formcheckbox {
                padding: 0 30px 0 0;

                .wm-field-label {
                    position: relative;

                    label {
                        position: absolute;
                        top: 0;
                        left: 24px;
                        padding: 0 10px;
                        margin: 0;
                        color: #2e6caf;
                        font-size: 12px;
                        background-color: #FFF;
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        transform: translateY(-50%);
                        z-index: 99;
                    }
                }

                .wm-field-input {
                    height: auto;
                    padding: 10px;
                    min-height: 54px;
                    border: 1px solid #30ab7a;
                    @include border-radius(10px);

                    .field {
                        @extend .c-checkbox;

                        > input {
                            @extend .c-checkbox__input;
                        }

                        > label {
                            @extend .c-checkbox__label;
                            color: #0a519d !important;
                            font-size: 16px !important;
                            width: auto !important;
                        }
                    }
                }
            }

            .formselect {
                width: 85%;
                max-width: 380px;

                .wm-field-label {
                    position: relative;

                    label {
                        position: absolute;
                        top: 0;
                        left: 24px;
                        padding: 0 10px;
                        margin: 0;
                        color: #2e6caf;
                        font-size: 12px;
                        background-color: #FFF;
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        transform: translateY(-50%);
                        z-index: 99;
                    }
                }

                .wm-field-input {
                    position: relative;
                    height: auto;
                    min-height: 54px;
                    border: 1px solid #30ab7a;
                    @include border-radius(10px);

                    &:after {
                        position: absolute;
                        top: 17px;
                        right: 15px;
                        @include bovemij-icon(chevron-circle-down);
                        font-size: 18px;
                        z-index: 1;
                    }

                    select {
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 52px;
                        border: 0 none;
                        padding: 0 24px;
                        @include border-radius(10px);
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        background-color: transparent;
                        cursor: pointer;
                        z-index: 2;

                        &::-ms-expand {
                            display: none;
                        }

                        &:focus {
                            outline: 0 none;
                        }
                    }
                }
            }

            .formbutton {
                input {
                    @extend .btn;
                    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);

                    &:focus {
                        outline: 0 none;
                    }
                }
            }

            &.volgende .formbutton input {
                @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
            }

            &.vorige .formbutton input {
                @include button-variant($btn-tertiary-color, $btn-tertiary-bg, $btn-tertiary-border);
            }
        }

        .formsection-default {
            position: relative;
            height: auto;
            padding: 20px 0 20px 20px;
            margin: 0 0 35px 0;
            border: 1px solid #30ab7a;
            @include border-radius(10px);

            .wm-section-label {
                label {
                    position: absolute;
                    top: 0;
                    left: 24px;
                    padding: 0 10px;
                    margin: 0;
                    color: #2e6caf;
                    font-size: 12px;
                    background-color: #FFF;
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);
                    z-index: 99;
                }
            }

            .fieldgrp:last-child {
                margin: 0;
            }
        }
    }
}

.o-block.c-form {
    &--contact {
        padding: 40px 10px 0 10px;

        @include media-breakpoint-up(md) {
            padding: 50px 50px 15px 50px;
        }
    }
}

/* XC formdate-default date picker dropdown */

.ui-datepicker {
    position: absolute;
    left: -99999px;
    width: 74%;
    padding: 15px;
    background-color: #FFF;
    border: 1px solid #30ab7a;
    @include border-radius(10px);
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);
    z-index: 999 !important;

    @include media-breakpoint-up(md) {
        width: auto;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        font-size: 12px;
        cursor: pointer;
    }

    .ui-datepicker-prev{
        float: left;

        &:before {
            @include bovemij-icon(arrow-previous);
            font-size: 10px;
            margin-right: 5px;
        }
    }

    .ui-datepicker-next{
        float: right;

        &:after {
            @include bovemij-icon(arrow-next);
            font-size: 10px;
            margin-left: 5px;
        }
    }

    .ui-datepicker-title {
        padding: 25px 0 5px 0;
        border-bottom: 1px solid #eee;
        .ui-datepicker-month,
        .ui-datepicker-year {
            width: 50%;
        }
    }

    .ui-datepicker-calendar {
        width: 100%;

        thead tr th span {
            font-size: 12px;
            text-align: center;
        }
        tbody tr td:not(.ui-datepicker-unselectable) {
            padding: 0;
            border: 1px solid #eee;
            a {
                color: #191919;
                padding: 4px;
                font-size: 14px;
                display: block;
                text-align: center;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

/* Firefox hack for file upload */
@-moz-document url-prefix() {
    fieldset.form .fieldgrp .formfileupload .wm-field-input input {
        height: 32px !important;
    }
}


.iaf-form-holder-outer {
    @extend .o-block;
}

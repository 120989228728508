.c-modal {
    &__dialog {
        position: relative;
        max-width: 100%;
        margin: 0;
        padding-bottom: 5px;
        background-color: #FFF;
        @include border-bottom-radius(0px);

        @include media-breakpoint-up(md) {
            max-width: 500px;
            margin: 30px auto;
            background-color: transparent;
        }

        @include media-breakpoint-up(lg) {
            max-width: 780px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: -webkit-linear-gradient(336deg, #29ac79, #113d83);
            background: -moz-linear-gradient(336deg, #29ac79, #113d83);
            background: -o-linear-gradient(336deg, #29ac79, #113d83);
            background: linear-gradient(66deg, #29ac79, #113d83);
            z-index: -1;
            
            @include media-breakpoint-up(md) {
                @include border-top-radius(10px);
            }
        }
    }

    &__content {
        border: 0 none;
        border-radius: 0;

        @include media-breakpoint-up(md) {
            border-top: 1px solid transparent;
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
            border-bottom: 0 none;
            @include border-top-radius(10px);
            @include border-bottom-radius(0);
        }
    }

    &__header {
        display: block;
        border: 0 none;
        padding: 0;

        @include media-breakpoint-up(md) {
            padding: 40px 50px;
        }
    }

    &__title {
        display: block;
        width: 100%;
        font-size: 22px;
        color: #123c81;
        line-height: 30px;
        padding: 0 10px;
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0;
        }
    }

    &__body {
        padding: 0 10px;

        @include media-breakpoint-up(md) {
            padding: 0 50px;
        }
    }

    &__footer {
        padding: 30px 10px;
        border: 0 none;
        justify-content: flex-start;

        @include media-breakpoint-up(md) {
            padding: 15px 50px 50px 50px;
        }

        &:before {
            position: absolute;
        }
    }

    &__close {
        &--mobile {
            position: relative;
            display: block;
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            text-align: left;
            color: #191919;
            padding: 0 10px;
            border-top: 0 none;
            border-right: 0 none;
            border-bottom: 1px solid #ececec;
            border-left: 0 none;
            margin-bottom: 25px;
            background: transparent;

            @include media-breakpoint-up(md) {
                display: none;
            }

            &:before {
                @include bovemij-icon(arrow-next);
            }
        }

        &--desktop {
            position: absolute;
            display: none;
            top: 20px;
            right: 20px;
            width: 32px;
            height: 32px;
            margin: 0;
            padding: 0;
            border: 0 none;
            background: transparent;
            text-indent: -99999px;
            cursor: pointer;

            @include media-breakpoint-up(md) {
                display: block;
            }
            
            &:focus {
                outline: 0 none;
                border: 0 none;
            }

            &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 32px;
                height: 32px;
                @include bovemij-icon(close);
                color: #0a519d;
                font-size: 32px;
                text-indent: 0;
            }
        }
    }
}

/* GX */

.modal .modal-dialog {
    @extend .c-modal__dialog;
}

.modal .modal-content {
    @extend .c-modal__content;
}

.modal .modal-content .modal-header {
    @extend .c-modal__header;
}

.modal .modal-content .modal-header .modal-title {
    @extend .c-modal__title;
}

.modal .modal-content .modal-header .close.close--mobile{
    @extend .c-modal__close--mobile;
}

.modal .modal-content .modal-header .close.close--desktop{
    @extend .c-modal__close--desktop;
}

.modal .modal-content .modal-body {
    @extend .c-modal__body;
}

.modal .modal-content .modal-footer {
    @extend .c-modal__footer;
}


.c-text {
    &__title {
        margin: 0 0 20px 0;
        font-size: 22px;
        font-weight: 500;
        color: #123c81;
        line-height: 1.5;
    }

    &__body {
        font-size: 16px;
        color: #191919;
        line-height: 1.5;
    }
}

/* XC */

.c-text .c-text__body *:last-child {
    margin-bottom: 0 !important;
}
.c-footer {
    background: #FFF;

    &__content {
        font-size: 14px;
        padding: 25px 45px;

        @include media-breakpoint-up(md) {
            padding: 50px 0;
        }
    }

    &__logo {
        display: block;

        img {
            width: 166px;
            height: 42px;
            height: auto;
            margin-bottom: 10px;
        }
    }

    &__about {
        max-width: 250px;
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
            max-width: none;
        }
    }

    &__text,
    &__text p {
        line-height: 2.14;
        color: $gray;
    }

    &__slogan {
        font-family: 'GT Walsheim Pro';
        font-size: 18px;
        font-weight: 500;
        color: #2e6caf;
        margin-top: 30px;
    }

    &__about-us-group {
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    &__links-group {
        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    &__border {
        position: relative;
        min-height: 60px;
        padding: 16px 0;
        color: $color-white;
        background-color: $color-green;
    }
}

.c-above-footer {
    ul {
        li {
            a {
                color: #000;
                text-decoration: none;
                line-height: 1.8em;
            }
        }
    }
}

.c-footer__social-links {
    position: absolute;
    top: 16px;
    left: 0;
    width: 40%;
    max-width: 148px;

    @include media-breakpoint-up(md) {
        top: 15px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            float: left;
            margin-right: 10px;

            @include media-breakpoint-up(md) {
                margin-right: 15px;
            }

            &:last-child{
                margin-right:0;
            }
        }
    }

    a {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        height: auto;
        overflow: hidden;
        color: transparent;
        text-decoration: none;

        &:before {
            color: #fff;
            font-size:28px;
        }
    }
}

.c-footer__links {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ffffff;
    padding: 10px 0;
    margin-top: 65px;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            margin-right: 40px;

            a {
                color: #FFF;
            }
        }
    }

    @include media-breakpoint-up(md) {
        position: absolute;
        display: block;
        height: 30px;
        justify-content: flex-start;
        border-top: 0 none;
        border-left: 1px solid #ffffff;
        padding: 0 0 0 30px;
        margin: 0;
        line-height: 30px;
        left: 148px;

        > a, a:visited {
            color: #FFF;
            margin-right: 40px;
        }
    }

    ul{
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            margin-right: 40px;

            a {
                color: #FFF;
            }
        }
    }
}

.c-footer__bovag {
    position: absolute;
    top: 22px;
    right: 0;
    width: 57%;
    height: 30px;
    max-width: 220px;
    text-align: right;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        top: 16px;
    }

    p {
        line-height: 30px;
        color: #FFF;
        margin-right: 83px;
        font-size: 12px;
        font-weight: 500;
    }

    img {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        height: 30px;
        width: auto;
        margin-left: 10px;
    }
}

/* GX */

footer, .footer {
    @extend .c-footer;
}

footer > .block-default > .footer-default > .block-default:last-child {
    background-color: $color-green;
}

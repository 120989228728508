.c-table {

    &--responsive {
        background-color: #fff;
        overflow: hidden;

        tr {
            display: block;
            padding: 15px 0;

            th {
                padding: 10px 20px;
                color: #123c81;
                font-size: 16px;
                border-bottom: 1px solid #dadada;
            }

            td {
                padding: 10px 20px;
                color: #0a519d;
                font-size: 14px;

                + td {
                    border-top: 1px solid #dadada;
                }

                &:last-child {
                    border-bottom: 2px solid #dadada;
                }

                &:nth-child(even) {
                    background-color: #f5f5f5;
                }
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
        thead {
            display: none;
        }

        tbody {
            tr {

                td {
                    display: block;
                }

                th {
                    display: block;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &--responsive {
            tr {
                display: table-row;
            }
            thead {
                display: table-header-group;
                tr {
                    td, th {
                        display: table-cell;

                        &:first-child {
                            display: none;
                        }
                    }
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: #f5f5f5;
                    }
                    th {
                        display: none;
                    }
                    td {
                        display: table-cell;
                        background-color: transparent;

                        + td {
                            border: 0;
                        }

                        &:last-child {
                            border: 0;
                        }

                        &:nth-child(even) {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}

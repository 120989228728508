.c-social-share {
    position: relative;
    @include clearfix;

    &__line {
        display: block;
        width: 100%;
        margin: 0;
        border-top: 0;
        border-right: 0;
        border-bottom: 1px solid #dadada;
        border-left: 0;
    }

    &__text {
        float: right;
        margin: 30px 10px 30px 0;
        line-height: 30px;
    }

    &__list {
        float: right;
        width: auto;
        height: 30px;
        margin: 0;
        padding: 30px 0;
        list-style: none;
    }

    &__item {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        background-color: #2e6caf;
        overflow: hidden;
        @include border-radius(5px);

        &:last-child {
            margin: 0;
        }
    }

    &__link {
        position: relative;
        width: 30px;
        height: 30px;
        display: block;
        color: transparent;
        text-decoration: none;
        @include border-radius(5px);

        &:hover {
            color: transparent;
            text-decoration: none;
        }

        &:focus {
            outline: 0 none;
        }

        &--facebook:before {
            @include bovemij-icon(facebook);
        }

        &--twitter:before {
            @include bovemij-icon(twitter);
        }

        &--linkedin:before {
            @include bovemij-icon(linkedin);
        }   

        &:before {
            position: absolute;
            top: 6px;
            left: 6px;
            color: #FFF;
            font-size: 18px;
        }
    }
}

/* XC */

.c-social-share .c-social-share__text * {
    margin: 0;
    line-height: 30px;
}
.c-nav {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 60px;
    padding: 60px 0;
    color: #FFF;
    z-index: 9999;
    background-color: #30ab7a;

    @include media-breakpoint-up(lg) {
       top: 80px;
       padding: 100px 0; 
    }

    &.is-hidden {
        display: none;
        visibility: hidden;
    }

    &.is-visible {
        display: block;
        visibility: visible;
    }

    &__section-title {
        font-size: 22px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    &__section-summary {
        display: none;
        visibility: hidden;

        p {
            color: #fff;
            margin-bottom: 10px;
        }
        
        @include media-breakpoint-up(lg) {
            display: block;
            visibility: visible;
            line-height: 1.85;
            font-size: 13px;
            margin-bottom: 50px;
        }
    }
}




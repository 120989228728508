.u-flex-first {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;

    &\@mobile {
        @include media-breakpoint-down(sm) {
            -webkit-box-ordinal-group: 1;
            -moz-box-ordinal-group: 1;
            -ms-flex-order: 1;
            -webkit-order: 1;
            order: 1;
        }
    }

    &\@tablet {
        @include media-breakpoint-only(md) {
            -webkit-box-ordinal-group: 1;
            -moz-box-ordinal-group: 1;
            -ms-flex-order: 1;
            -webkit-order: 1;
            order: 1;
        }
    }

    &\@desktop {
        @include media-breakpoint-up(lg) {
            -webkit-box-ordinal-group: 1;
            -moz-box-ordinal-group: 1;
            -ms-flex-order: 1;
            -webkit-order: 1;
            order: 1;
        }
    }
}

.u-flex-last {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;

    &\@mobile {
        @include media-breakpoint-down(sm) {
            -webkit-box-ordinal-group: 2;
            -moz-box-ordinal-group: 2;
            -ms-flex-order: 2;
            -webkit-order: 2;
            order: 2;
        }
    }

    &\@tablet-and-down {
        @include media-breakpoint-down(md) {
            -webkit-box-ordinal-group: 2;
            -moz-box-ordinal-group: 2;
            -ms-flex-order: 2;
            -webkit-order: 2;
            order: 2;
        }
    }

    &\@tablet {
        @include media-breakpoint-only(md) {
            -webkit-box-ordinal-group: 2;
            -moz-box-ordinal-group: 2;
            -ms-flex-order: 2;
            -webkit-order: 2;
            order: 2;
        }
    }



    &\@desktop {
        @include media-breakpoint-up(lg) {
            -webkit-box-ordinal-group: 2;
            -moz-box-ordinal-group: 2;
            -ms-flex-order: 2;
            -webkit-order: 2;
            order: 2;
        }
    }
}

%align-self-center {
    @extend .align-self-center;
}

.u-align-self-center{
    &\@mobile {
        @include media-breakpoint-down(sm) {
            @extend %align-self-center;
        }
    }

    &\@tablet {
        @include media-breakpoint-only(md) {
            @extend %align-self-center;
        }
    }

    &\@tablet-and-up {
        @include media-breakpoint-up(md) {
            @extend %align-self-center;
        }
    }

    &\@desktop {
        @include media-breakpoint-up(lg) {
            @extend %align-self-center;
        }
    }
}

%align-self-end {
    @extend .align-self-end;
}

.u-align-self-end {
    &\@desktop {
        @include media-breakpoint-only(lg) {
            @extend %align-self-end;
        }
    }
}

.u-pt-50 {
    padding-top: 50px !important;

    &\@desktop {
        @include media-breakpoint-up(lg) {
            padding-top: 50px !important;
        }
    }
}

.u-pt-75 {
    padding-top: 75px;
}
.c-speech-bubble { 
    width: 100%;
    padding: 20px 20px 60px 20px;
    border-radius: 20px;

    &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        z-index: 2;
        background: #FFF;
    }

    &:after {
        z-index: 1;
    }

    &__arrow {
        position: absolute;
        right: 130px;
        bottom: 0;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border-width: 0px 40px 30px 0px;
            border-style: solid;
            border-color: transparent #FFF;
            display: block;
            width: 0;
            z-index: 2;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border-width: 2px 40px 30px 0px;
            border-style: solid;
            border-color: transparent #dadada;
            display: block;
            width: 0;
            z-index: 1;
        }
    }

    &__title {
        position: relative;
        color: #123c81;
        height: 24px;
        margin-bottom: 5px;
        font-family: 'GT Walsheim Pro';
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        color: #123c81;
        z-index: 3;
    }

    &__content {
        position: relative;
        margin: 0 0 20px 0;
        font-family: 'GT Walsheim Pro';
        font-size: 16px;
        line-height: 1.5;
        color: #1a1a1a;
        z-index: 3;
    }

    &__button {
        position: absolute;
        left: 20px;
        bottom: 20px;
        width: 170px;
        z-index: 9;
    }
}
.u-company-type {
    &:before {
        position: absolute;
        bottom: 0;
        left: 75%;
        -webkit-animation: companyTypeModalAnimation 2s ease-out;
        -moz-animation: companyTypeModalAnimation 2s ease-out;
        -o-animation: companyTypeModalAnimation 2s ease-out;
        animation: companyTypeModalAnimation 2s ease-out;
    }

    &--trailer {
        &:before {
            @include bovemij-icon(aanhangwagen);
            font-size: 30px;
            margin: -6px 0;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                margin: -10px 0;
            }
        }
    }

    &--auto-and-motor {
        &:before {
            @include bovemij-icon(personenwagen);
            font-size: 30px;
            margin: -6px 0;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                margin: -10px 0;
            }
        }
    }

    &--car-wash-and-polish {
        &:before {
            @include bovemij-icon(was-en-poets);
            font-size: 28px;
            margin: -1px 0;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                margin: -3px 0;
            }
        }
    }

    &--commercial-vehicle-care {
        &:before {
            @include bovemij-icon(bestelbus);
            font-size: 28px;
            margin: -4px 0;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                margin: -7px 0;
            }
        }
    }

    &--caravan-and-camper {
        &:before {
            @include bovemij-icon(caravan);
            font-size: 26px;
            margin: -2px 0;
            @include media-breakpoint-up(md) {
                font-size: 40px;
                margin: -4px 0;
            }
        }
    }

    &--auditing {
        &:before {
            left: 75%;
            @include bovemij-icon(revisie);
            font-size: 24px;
            margin: 0 0 2px 0;
            @include media-breakpoint-up(md) {
                font-size: 36px;
                margin: 0 0 2px 0;
            }
        }
    }

    &--driving-school {
        &:before {
            @include bovemij-icon(rijschool);
            font-size: 30px;
            margin: -1px 0;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                margin: -3px 0;
            }
        }
    }

    &--damage-repair {
        &:before {
            left: 75%;
            @include bovemij-icon(schadeherstel);
            font-size: 24px;
            margin: 0;
            @include media-breakpoint-up(md) {
                font-size: 38px;
                margin: -2px 0;
            }
        }
    }

    &--gas-station {
        &:before {
            left: 75%;
            @include bovemij-icon(tankstation);
            font-size: 24px;
            margin: 0;
            @include media-breakpoint-up(md) {
                font-size: 36px;
                margin: 0;
            }
        }
    }

    &--truck {
        &:before {
            @include bovemij-icon(vrachtwagen);
            font-size: 30px;
            margin: -5px 0;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                margin: -9px 0;
            }
        }
    }

    &--bodywork {
        &:before {
            left: 75%;
            @include bovemij-icon(carosserie);
            font-size: 30px;
            margin: -9px 0;
            @include media-breakpoint-up(md) {
                font-size: 40px;
                margin: -14px 0;
            }
        }
    }

    &--two-wheeler {
        &:before {
            @include bovemij-icon(fiets);
            font-size: 30px;
            margin: -5px 0;
            @include media-breakpoint-up(md) {
                font-size: 40px;
                margin: -8px 0;
            }
        }
    }

    &--auto {
        &:before {
            @include bovemij-icon(personenwagen);
            font-size: 30px;
            margin: -6px 0;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                margin: -10px 0;
            }
        }
    }

    &--motor {
        &:before {
            @include bovemij-icon(motor);
            font-size: 30px;
            margin: -6px 0;
            @include media-breakpoint-up(md) {
                font-size: 45px;
                margin: -10px 0;
            }
        }
    }

    @at-root {
        @-webkit-keyframes companyTypeModalAnimation {
            0%   { left: 0; }
            100% { left: 75%; }
        }

        @-moz-keyframes companyTypeModalAnimation {
            0%   { left: 0; }
            100% { left: 75%; }
        }

        @-o-keyframes companyTypeModalAnimation {
            0%   { left: 0; }
            100% { left: 75%; }
        }

        @keyframes companyTypeModalAnimation {
            0%   { left: 0; }
            100% { left: 75%; }
        }
    }
}


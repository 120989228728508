@mixin align-content($value: stretch) {
	-webkit-align-content: $value;
	-moz-align-content: $value;
	@if $value == flex-start {
		-ms-flex-line-pack: start;
	} @else if $value == flex-end {
		-ms-flex-line-pack: end;
	} @else {
		-ms-flex-line-pack: $value;
	}
	align-content: $value;
}
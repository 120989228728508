.c-anchor-nav {
    position: relative;
    margin-top: 100px;
    z-index: 70;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }

    &__links {
        width: 100%;
        padding: 15px 0 0 15px;
        background-color: #30ab7a;
        @include border-bottom-radius(10px);

        @include media-breakpoint-up(lg) {
            height: 70px;
            padding: 0;
            background-color: transparent;
        }
    }

    &__prefix {
        position: relative;
        color: #FFF;
        font-size: 14px;
        font-weight: 300;
        padding: 0 0 12px 0;

        @include media-breakpoint-up(lg) {
            height: 70px;
            padding: 0;
            display: inline-block;
            margin-right: 15px;
            line-height: 70px;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(to right, rgba(48, 171, 122, 0), #1f9465 34%, rgba(31, 148, 101, 0.77) 71%, rgba(48, 171, 122, 0));

            @include media-breakpoint-up(lg) {
                content: none;
            }
        }
    }

    &__item {
        width: 100%;
        height: auto;

        @include media-breakpoint-up(lg) {
            display: inline-block;
            width: auto;
            height: 70px;
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        color: #FFF;
        font-size: 14px;
        font-weight: 300;
        padding: 12px 0;

        @include media-breakpoint-up(lg) {
            height: 70px;
            display: inline-block;
            line-height: 70px;
            padding: 0;
        }

        &:before, &:after {
            position: absolute;
            top: 50%;
            -moz-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);

            @include media-breakpoint-up(lg) {
                left: 50%;
                top: auto;
                -moz-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
            }
        }

        &:before {
            content: "";
            right: -10px;
            width: 20px;
            height: 20px;
            background-color: #30ab7a;

            @include media-breakpoint-up(lg) {
                right: auto;
                bottom: -10px;
            }
        }

        &:after {
            right: -14px;
            @include bovemij-icon(arrow-circle-down);
            font-size: 28px;
            color: #FFF;

            @include media-breakpoint-up(lg) {
                right: auto;
                bottom: -14px;
            }
        }
    }

    &__background {

        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 0;
            left: 0;
            width: 1920px;
            height: 70px;
            margin: 0 -485px;
            z-index: -1;
        }

        &:before {
            @include media-breakpoint-up(lg) {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 1020px;
                height: 70px;
                background-color: #30ab7a;
            }
        }

        &:after {
            @include media-breakpoint-up(lg) {
                content: "";
                position: absolute;
                top: 0;
                left: 971px;
                width: 89px;
                height: 70px;
                background-image: url("../../img/bg_anhor-nav_r-corner.png");
                background-repeat: no-repeat;
            }

            @include media-breakpoint-up(lg) {
                left: 1020px;
            }
        }
    }
}

/* GX */

.c-anchor-nav__item a {
    @extend .c-anchor-nav__link;
    text-decoration: none;
}

@include media-breakpoint-up(lg) {
    main.embhl .c-anchor-nav__background { margin: 0 -500px; }
}

.c-content-header {
    margin-top: 30px;

    & + #column-2 {
        margin-top: 0 !important;
    }

    .back-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        text-decoration: none !important;
        font-weight: lighter;

        .icon {
            font-size: 1.5em;
        }
    }

    .content-date {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        gap: 8px;
        font-weight: lighter;

        & > .icon {
            margin-bottom: 3px;
            color: #123C81;
        }
    }
}


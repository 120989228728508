.o-block {
    position: relative;
    padding: 30px 15px;
    background-color: #FFF;
    border-bottom: 2px solid #dadada;
    @include border-radius(10px);

    @include media-breakpoint-up(md) {
        padding: 30px 35px;
    }

    &--rectangles {
        @include media-breakpoint-up(md) {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
    }

    &__rectangle {
        color: #123c81;
        padding: 30px 15px;
        border-bottom: 1px solid #d8d8d8;

        @include media-breakpoint-up(md) {
            display: table-cell;
            padding: 70px 30px;
            border-right: 1px solid #d8d8d8;
            border-bottom: 0 none;
        }

        &:last-child {
            border: 0 none;
        }

        &--small-padding {
            padding: 25px 20px;
        }

        &--medium-padding {
            &\@tablet-and-up {
                @include media-breakpoint-up(md) {
                 padding: 48px 30px;
                }
            } 
        }
    }

    &--no-borders {
        border: 0 none;
    }

    &--no-rounded-corners {
        @include border-radius(0);
    }

    &--no-padding {
        padding: 0;
    }
}
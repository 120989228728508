.c-about-us-group {

    &__title {
        max-width: 250px;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            max-width: 100%;

        }
    }
    &__list {
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
        padding: 0 16px;

        @include media-breakpoint-up(md) {
            padding: 0;
            max-width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}
